<template>
    <section class="container sign-container">
        <h1 class="text-center fs-1 fw-bold create-account-text">Create an account</h1>
        <p class="text-center already-signed-text">Already signed up? <a href="/therapist/sign-in" class="internal-link">Log in</a></p> 

            <div class="create-account-buttons-wrapper" v-if="signMethodsForm">
                <!-- <button class="sign-button apple-sign" @click="signInWithApple"><img src="../assets/images/apple-logo.svg" alt="">Continue with Apple</button>
                <vue-apple-login
                    :onSuccess="onSuccess"
                    :onFailure="onFailure"
                ></vue-apple-login> -->

                <!-- <button class="sign-button google-sign" @click="handleAuthClick"><img src="../assets/images/google-logo-colored.svg" alt="">Continue with Google</button>
                <div class="sign-separator w-100">
                    <p class="sign-separator-text text-center">Or</p>
                </div> -->
                <button class="sign-button google-sign" @click="handleAuthClick"><img src="../assets/images/google-logo-colored.svg" alt="">Continue with Google</button>
                <div class="sign-separator w-100">
                    <p class="sign-separator-text text-center">Or</p>
                </div>
                <button class="sign-button email-sign" @click="goToSignWithEmailForm()"><img src="../assets/images/email-icon-black.svg" alt="">Continue with Email</button>
            </div>

            <div class="create-account-fields-wrapper d-flex flex-column align-items-center" v-if="signWithEmailForm">
                <div class="sign-field-block w-100">
                        <h6>First name</h6>
                        <input type="text" class="w-100 sign-field" v-model="firstName" placeholder="Enter your first name">
                </div>
                <div class="sign-field-block w-100">
                        <h6>Last name</h6>
                        <input type="text" class="w-100 sign-field" v-model="lastName" placeholder="Enter your last name">
                </div>
                <div class="sign-field-block w-100">
                        <h6>Email</h6>
                        <input type="email" class="w-100 sign-field" v-model="userEmail" placeholder="Enter your email">
                </div>
                <div class="sign-field-block w-100">
                        <h6>Password</h6>
                        <input type="password" class="w-100 sign-field" v-model="userPassword" placeholder="Enter your password">
                </div>
                <div class="sign-button-block">
                    <button class="next-step-button" @click="signUpTherapist()">Create an account</button>
                </div>
            </div>   

        <p class="text-center">Creating an account means you're okay with our <a href="/terms-and-conditions" class="internal-link">Terms of Service</a> and <a href="/privacy-policy" class="internal-link">Privacy Policy</a></p>
     </section>
</template>

<script>
import axios from '@/axios';
import { gapi } from 'gapi-script';

export default{
    data(){
        return{
            signWithEmailForm: false,
            signMethodsForm: true,
            firstName: '',
            lastName: '',
            userPassword: '',
            userEmail: '',
            referralCode: null,
            userData: null,
            signedIn: false,
            AppleID: null,
        }
    },
    mounted(){
        this.handleClientLoad();
    },
    methods: {
        goToSignWithEmailForm(){
            this.signWithEmailForm = true;
            this.signMethodsForm = false;
        },
        handleClientLoad() {
            gapi.load('client:auth2', this.initClient);
        },
        initClient() {
            gapi.client.init({
                clientId: '200963144297-q7h4rc7ouoqn4a7r6erpe3kqsqfck6op.apps.googleusercontent.com',
                scope: 'email'
            }).then(() => {
                this.GoogleAuth = gapi.auth2.getAuthInstance();
            });
        },
        handleAuthClick() {
            this.GoogleAuth.signIn().then(async (googleUser) => {
                // Тут ваш код после успешного входа, например:
                await axios.post('/therapist/google-sign-up', {
                    first_name: googleUser.getBasicProfile().getGivenName(),
                    last_name: googleUser.getBasicProfile().getFamilyName(),
                    email: googleUser.getBasicProfile().getEmail(),
                }).then((response) => { // использование стрелочной функции здесь
                    this.$store.dispatch('setTokens', {
                        access: response.data.token, 
                        refresh: response.data.refresh_token
                    });
                    this.$store.dispatch('setTherapistData', {
                        id: response.data.user.practitioner_id,
                        firstName: response.data.user.first_name,
                        lastName: response.data.user.last_name,
                        email: response.data.user.email,
                        isLoggedIn: true
                    })
                    this.$router.push('/therapist/easy-steps');
                })
            }).catch(error => {
                console.error(error);
            });
        },
        async signUpTherapist(){
            try {
                await axios.post('/therapist/register', {
                    name: this.firstName,
                    last_name: this.lastName,
                    email: this.userEmail,
                    password: this.userPassword,
                }).then((response) => { // использование стрелочной функции здесь
                    this.$store.dispatch('setTokens', {
                        access: response.data.token, 
                        refresh: response.data.refresh_token
                    });
                    this.$store.dispatch('setTherapistData', {
                        id: response.data.user.practitioner_id,
                        firstName: response.data.user.first_name,
                        lastName: response.data.user.last_name,
                        email: response.data.user.email,
                        isLoggedIn: true
                    })
                    this.$router.push('/therapist/easy-steps');
                })
            } catch (error) {
                this.$router.push('/');
                console.error('Ошибка при получении данных от API:', error)
            }
        },
    }
}
</script>

<style scoped>
     .create-account-fields-wrapper {
        width: 532px;
    }

    .create-account-text{
        font-size: 38px !important;
    }

    .sign-button{
        padding: 12px 24px 12px 24px;
        border-radius: 40px;
        font-size: 17px;
        font-weight: 600;
        line-height: 28px;
        height: 52px;
        color: #fff;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
    }

    .sign-field{
        background: #fff;
        height: 44px;
        border: 1px solid #E6E8ED;
        border-radius: 8px;
        padding: 10px 12px 10px 12px;
        line-height: 24px;
    }

    h6{
        font-weight: 600;
    }

    .sign-button-block{
        margin-bottom: 32px;
        width: 312px;
    }

    .already-signed-text{
        margin-bottom: 32px;
    }

    .privacy-text{
        font-size: 14px;
        width: 311px;
    }

    .apple-sign{
        background: #000;
    }

    .google-sign{
        background: #D95040;
    }

    .facebook-sign{
        background: #3479EA;
    }

    .email-sign{
        background: #00D2FF;
        color: #000;
    }

    .sign-button img{
        margin-right: 8px;
    }

    .sign-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
        margin-bottom: 110px;
    }

    .create-account-buttons-wrapper{
        display: flex;
        flex-direction: column;
        width: 311px;
    }

    .sign-separator-text{
        position: relative;
    }
    .sign-separator-text::before,
    .sign-separator-text::after
    {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        width: 40%;
        background-color: #DADCE4; /* Измените на нужный цвет */
    }

    .sign-separator-text::before {
        top: 10px;
    }

    .sign-separator-text::after {
        bottom: 13px; /* Измените на нужное расстояние */
        left: 59%;
    }

    .sign-field-block{
        margin-bottom: 24px;
    }
    
    .error{
        border-color: #FF4E4E;
    }
    
    .error-text{
        color: #FF4E4E;
        font-size: 14px;
    }

    .internal-link{
        color: #00809D;
        text-decoration: none;
    }

    .google-sign{
        background: #fff;
        border: 1px solid #D0D5DD;
        color: #000;
    }

    @media (max-width: 570px) { 
        .create-account-fields-wrapper{
            width: 100%;
        }
    }
</style>