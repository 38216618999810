import { createApp } from 'vue'
import App from './App.vue'
import tooltip from "./directives/tooltip.js";
import "@/assets/css/tooltip.css";
import './assets/css/styles.css'
import 'bootstrap/dist/css/bootstrap.css'
import store from './store'
import router from './router'
import 'jquery'
import 'popper.js'
import 'bootstrap'
import { apiURL } from '../global.js'
import vue3GoogleLogin  from "vue3-google-signin";

const gAuthOption = {
  clientId: '200963144297-q7h4rc7ouoqn4a7r6erpe3kqsqfck6op.apps.googleusercontent.com',
}

createApp(App).use(router).use(store)
    .use(vue3GoogleLogin, gAuthOption)
    .directive("tooltip", tooltip).provide('apiUrlStart', apiURL).mount('#app')