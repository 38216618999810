<template>
    <div class="container">

            <div class="breadcrumbs d-flex">
                <div class="breadcrumb-element d-flex"><img src="../../assets/images/home-icon.svg" alt=""><a href="/">Home</a></div>
                <img src="../../assets/images/arrow-right.svg" alt="">
                <div class="breadcrumb-element d-flex"> <a href="/get-help">Get help right now</a></div>
                <img src="../../assets/images/arrow-right.svg" alt="">
                <div class="breadcrumb-element d-flex">{{ data.issue_name }}</div>
            </div>
            <section class="w-100 type-section d-flex flex-column align-items-center">
                <h1 class="fw-bold text-center">{{ data.issue_name }}</h1>
                <p class="text-center type-description">{{ data.description }}</p>

                <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-xs-8 booking-data">
                    <h2>Session details</h2>
                    <hr>
                    <div class="detail-block-section">
                        <h4>Service:</h4>
                        <p>Get help right now</p>
                    </div>
                    <div class="detail-block-section">
                        <h4>Duration:</h4>
                        <p>15 Minutes</p>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-xs-8 d-flex flex-column align-items-center booking-button-wrapper">
                    <button class="btn btn-primary" @click="$router.push({name: 'get_help_search_therapists', query: { 
                                    issueId: data.issue_id
                                }} )">Search available Therapist</button>
                    <p class="text-center">Finding a Therapist will take 2-3 minutes and after it, you can start the session in a few minutes.</p>
                </div>
                
            </section>
    </div>
  </template>
  
  <script>
  import axios from '@/axios';

  export default {
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    data(){
        return{
            therapistData: {},
            isFirstStage: true,
            isSecondStage: false,
            isThirdStage: false,
            isSearching: true,
            isTherapistFound: false,
            noAvailableTherapists: false,
            customerId: null,
        }
    },
    mounted(){
        this.getClientCards();
    },
    methods: {
        async getClientCards() {
            try {
                const response = await axios.get('/payment/stripe/get-client-cards', {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`
                        }
                    });
                if (response.data && response.data.length > 0) {
                    this.customerId = response.data[0].customer_id;
                }
            } catch (error) {
                console.error('Произошла ошибка при получении карт клиента:', error);
            }
        },
        async bookSession(){
            try {
                await axios.post('/appointment/create-session', {
                        practitioner_id: null,
                        fee: 25,
                        booking_type: 'get-help-right-now'
                    }, 
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`
                        }
                    }).then(async (response) => { 
                        if(this.customerId != null){
                            this.payWithExistingCard(response.data);
                        }else{
                            this.$router.push({name:'add_card', query: { 
                                    // therapistsData: response.data,
                                    session: JSON.stringify(response.data),
                                    price: 25,
                                    process: "get-help-process",
                                    issueId: this.data.issue_id
                                } 
                            });
                        }
                        
                    })
            } catch (error) {
                this.$router.push('/');
                console.error('Ошибка при получении данных от API:', error)
            }
        },

        async payWithExistingCard(sessionData){
            try {
                await axios.post('payment/stripe/existing-card-payment', {
                        session_id: sessionData.session_id,
                        fee_amount: 25,
                        customer_id: this.customerId
                    }, 
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`
                        }
                    }).then(() => { // использование стрелочной функции здесь
                        this.$router.push({name: 'get_help_session_is_booked', query: {
                            issueId: this.data.issue_id
                        }})
                    })
            } catch (error) {
                this.$router.push('/');
                console.error('Ошибка при получении данных от API:', error)
            }
        },
    }
  };
  </script>

  <style scoped>
    .breadcrumbs{
        padding: 18px 0px;
    }
    .breadcrumb-element{
        margin-left: 6px;
        margin-right: 6px;
        cursor: pointer;
    }
    .breadcrumb-element a{
        text-decoration: none;
        color: #13204E;
    }

    .breadcrumb-element img{
        margin-right: 6px;
    }
    .type-section{
        padding: 38px 0px 60px;
    }
    h1{
        font-size: 38px;
        line-height: 46px;
        margin-bottom: 12px;
    }

    h2{
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 0px;
    }

    h4{
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 4px;
    }
    .detail-block-section{
        margin-bottom: 16px;
    }
    .detail-block-section:last-child{
        margin-bottom: 0px;
    }
    .detail-block-section p{
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0px;
    }
    .detail-block-section span{
        padding: 4px 12px;
        background: #3A466E;
        border-radius: 15px;
        width: fit-content;
        color: #fff;
        font-size: 12px;
    }
    .type-section{
        line-height: 24px;
    }
    .type-description{
        margin-bottom: 32px;
    }
    
    .booking-data{
        background: #fff;
        padding: 32px;
        border-radius: 16px;
        margin-bottom: 32px;
    }
    .btn-primary {
        padding: 8px 0;
        border-radius: 22px;
        background: linear-gradient(46deg, #43C9F3 0%, #3CAEE0 100%);
        color: #fff;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
        margin-bottom: 24px;
    }
    .btn-primary img {
        margin: 0px 12px;
    }
    .booking-button-wrapper p{
        width: 85%;
        font-size: 14px;
        line-height: 22px;
    }
    .therapist-basic-description{
        width: 100%;
        display: flex;
        align-items: center;
    }
    .therapist-basic-description img{
        width: 96px;
        height: 96px;
    }
    .therapist-name{
        font-size: 20px;
        font-family: Libre Franklin;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 0px;
    }
    .therapist-basic-description p{
        margin-bottom: 0px;
    }
    .text-part{
        margin-left: 8px;
    }

    .therapist-specialization{
        padding: 4px 12px;
        background: #9DA3B7;
        border-radius: 15px;
        width: fit-content;
        color: #fff;
        font-size: 12px;
        margin-right: 8px;
    }
    .therapist-specialization-title{
        margin-bottom: 12px;
    }
    .all-specs-text{
        margin-top: 14px;
        margin-bottom: 0px;
    }
  </style>