<template>
    <section class="container h-100 d-flex align-items-center">
        <div class="w-100 d-flex align-items-center flex-column main-wrapper">
            <h1 class="text-center fw-bold" style="margin-top: 60px;">Chats</h1>
            <div class="steps-wrapper-block">
                <div class="chat-wrapper-top d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <div class="back-arrow-button-block" @click="$router.push('/chats')">
                            <img style="width: 9px; margin-right: 3px;" src="../assets/images/arrow-left-dark.svg" alt="">
                        </div>
                        <div style="margin-left: 24px;" class="d-flex" v-if="dialog != null">
                            <div class="contact-image-block">
                                <img :src="dialog.occupant.main_image" alt="" class="img-fluid rounded-circle contact-image">
                            </div>
                            <div>
                                <h2 class="therapist_name" style="margin-bottom: 0px; font-size: 20px;">{{ dialog.occupant.full_name }}</h2>
                                <div v-if="$store.state.therapist.id == null" class="d-flex">
                                    <p  class="therapist_provider_type" 
                                        style="margin-bottom: 0px; font-size: 16px; margin-right: 5px;"
                                        v-for="(provider, index) in dialog.occupant.provider_types" 
                                        :key="index"
                                    >{{provider}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                       <span v-if="$store.state.therapist.id == null" 
                             class="profile-view-button"
                             style="cursor: pointer"
                             @click="$router.push({name: 'recommended_therapist', query: {practitionerId: dialog.occupant.practitioner_id}})"
                        >See profile</span> 
                    </div>
                </div>
                <div class="chat-wrapper-body" ref="chatWrapperBody">
                    <!-- <span class="chat-date" style="margin-bottom: 12px;">24 December</span> -->
                    <div class="w-100" v-for="message in messages" :key="message._id">   
                        <div class="w-100 companion-message-block" v-if="message.sender_id != user.cc_id">
                            <div style="width: 320px; align-items: flex-end; padding: 12px" class="d-flex">
                                <span class="message-body">{{message.message}}</span>
                                <span class="message-time">{{convertToTimeString(message.date_sent)}}</span>
                            </div>
                        </div>
                        <div class="w-100 my-message-block" v-else>
                            <div class="my-message d-flex">
                                <span class="message-body">{{message.message}}</span>
                                <div class="d-flex flex-column align-items-end justify-content-end h-100">
                                    <!-- <img style="margin-top: 12px;" src="../assets/images/readen-sign.svg" alt="">  -->
                                    <span class="message-time">{{convertToTimeString(message.date_sent)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chat-wrapper-bottom d-flex align-items-center">
                    <div style="width: 85%">
                        <input type="text" v-model="messageText" class="sign-field w-100" placeholder="Enter your message">
                    </div>
                    <div style="width: 15%" class="d-flex justify-content-center">
                        <div 
                            style="margin-left: 20px; background: #BDC2C4; width: 24px; height: 24px; border-radius: 15px" 
                            class="d-flex justify-content-center align-items-center"
                            @click="sendMessage()"    
                        >
                            <img src="../assets/images/full-white-arrow-top.svg">
                        </div>
                    </div>
                </div>
            </div> 
        </div> 
    </section>
</template>

<script>
import connectyCube from "../config/connectycube-config"
import axios from '@/axios';

export default{
    data(){
        return {
            dialog: null,
            dialogId: null,
            user: null,
            occupantId: null,
            messageText: '',
            messages: [],
        }
    },
    methods: {
        async connectUser() {
            await connectyCube.createSession();
            
            const userCredentials = {
                id: this.user.cc_id,
                password: this.user.cc_password,
                login: this.user.cc_username
            }

            await connectyCube.login(userCredentials);

            await connectyCube.chat.connect({
                userId: userCredentials.id,
                password: userCredentials.password
            })
        },
        async getMyUserData(){
            if(this.$store.state.user.firstName != null){
                await axios.get('client/profile/show ', {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    },
                }).then((response) => {
                    this.user = response.data;
                });
            } else if (this.$store.state.therapist.id != null) {
                await axios.get('therapist/profile/', {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    },
                }).then((response) => {
                    this.user = response.data;
                });
            }
        },
        async sendMessage() {
            if(this.messageText != ''){
                const messageParams = {
                    type: "chat",
                    body: this.messageText,
                    extension: {
                        save_to_history: 1,
                        dialog_id: this.dialog._id,
                    },
                    markable: 1,
                };

                try {
                    await connectyCube.chat.send(this.occupantId, messageParams);

                    await this.loadMessages();
                } catch (error) {
                    console.error("Ошибка при отправке сообщения", error);
                }
                // await this.loadMessages();
            }

            this.messageText = "";
        },
        scrollToBottom() {
            // Получение доступа к элементу через ref
            const container = this.$refs.chatWrapperBody;

            // Установка свойства scrollTop равным высоте контента в элементе,
            // что приведёт к прокрутке к нижней границе элемента
            container.scrollTop = container.scrollHeight;
        },
        setupMessageListener() {
            // Установка обработчика входящих сообщений
            connectyCube.chat.onMessageListener = (userId, message) => {
                // Добавление сообщения в список
                this.messages.push({
                    id: message._id,
                    sender_id: userId,
                    date_sent: message.extension.date_sent,
                    message: message.body,
                });
        
                setTimeout(500);

                this.scrollToBottom();
            };
        },
        async loadMessages() {
            const params = { chat_dialog_id: this.dialogId, sort_asc: 'date_sent', limit: 100 };

            await connectyCube.chat.message
                .list(params)
                .then((result) => {
                    this.messages = result.items;
                })
                .catch((error) => {
                    console.error("Error retrieving messages", error);
                });
            
            setInterval(100);

            this.scrollToBottom();
        },
        async getDialog(){
            const retrievedDialog = (await connectyCube.chat.dialog.list({'_id': this.dialogId})).items[0];

            retrievedDialog.occupants_ids.splice(retrievedDialog.occupants_ids.indexOf(this.user.cc_id), 1);

            if(this.$store.state.therapist.id != null){
                retrievedDialog.occupant = JSON.parse(retrievedDialog.extensions.client);
            } else {
                retrievedDialog.occupant = JSON.parse(retrievedDialog.extensions.practitioner);
            }

            this.occupantId = retrievedDialog.occupants_ids[0];

            this.dialog = retrievedDialog;
        },
        convertToTimeString(timestamp) {
            // Создание объекта Date из Unix timestamp (в секундах)
            // Умножение на 1000, потому что JavaScript ожидает миллисекунды
            const date = new Date(timestamp * 1000);

            // Получение часов и минут
            const hours = date.getHours();
            const minutes = date.getMinutes();

            // Форматирование часов и минут для вывода в формате HH:MM
            // Добавление ведущего нуля, если число меньше 10
            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');

            // Возвращение конечной строки формата "часы:минуты"
            return `${formattedHours}:${formattedMinutes}`;
        },
    },
    async mounted(){
        this.dialogId = this.$route.query.dialogId;
        await this.getMyUserData();
        await this.connectUser();
        await this.getDialog();
        await this.loadMessages();
        this.setupMessageListener();
    }
}
</script>

<style scoped>
.steps-wrapper-block{
    width: 612px;
    border-radius: 16px;
    margin-bottom: 32px;
    margin-top: 32px;
    border: 1px solid #E8E9EA;
}

.chat-wrapper-top{
    background: #7BD9F7;
    border-radius: 16px 16px 0px 0px;
    padding: 24px;
}

.chat-wrapper-body{
    display: flex;
    align-items: center;
    background: #fff;
    padding: 20px 24px 20px 24px;
    height: 502px;
    overflow-y: scroll;
    scrollbar-width: thin; /* "auto" или "thin" */
    scrollbar-color: #888 #f1f1f1; /* Цвет подвижного элемента и дорожки */
    flex-direction: column;
}

.back-arrow-button-block{
    width: 32px; 
    height: 32px; 
    background: #FFFFFF; 
    border: 1px solid #E8E9EA; 
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-image{
    width: 48px;
    height: 48px;
    border: 1px solid #13204E;
}

.contact-image-block{
    margin-right: 12px;
}

.chat-messages-quantity-block{
    width: 16px; 
    height: 16px; 
    background: #F75384; 
    border-radius: 16px; 
    position: relative; 
    display: flex; 
    justify-content: center; 
    align-items: center;
}

.profile-view-button{
    padding: 4px 24px 4px 24px;
    background: #fff;
    border-radius: 15px;
    border: 1px solid #3A466E;
    font-size: 18px;
    font-weight: bold;
}

/* Стилизация скроллбара для контейнера с классом .custom-scroll */
.chat-wrapper-body::-webkit-scrollbar {
  width: 4px; /* Ширина скроллбара */
}

.chat-wrapper-body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Цвет фона дорожки */
}

.chat-wrapper-body::-webkit-scrollbar-thumb {
  background: #888; /* Цвет подвижного элемента */
  border-radius: 4px;
}

.chat-wrapper-body::-webkit-scrollbar-thumb:hover {
  background: #555; /* Темный цвет для ховера */
}

.chat-date{
    font-size: 16px;
    font-weight: 400;
}

.companion-message-block{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 12px;
}

.message-body{
    font-size: 20px;
    line-height: 28px;
    margin-right: 16px;
}

.message-time{
    font-size: 14px;
    color: #515354;
}

.my-message-block{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
}

.my-message{
    align-items: flex-end; 
    padding: 12px;
    background: #F6FBFF;
    border-radius: 12px 12px 0px 12px;
}

.chat-wrapper-bottom{
    width: 100%;
    background: #fff;
    padding: 24px;
    border-radius: 0px 0px 16px 16px;
    border-top: 1px solid #E8E9EA;
}

.sign-field{
    background: #fff;
    height: 44px;
    border: none;
    border-radius: 8px;
    padding: 10px 12px 10px 12px;
    line-height: 24px;
    border: 1px solid #E8E9EA;
}

</style>