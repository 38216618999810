<template>
    <div class="container">
            <div class="breadcrumbs d-flex">
            </div>
            <section class="w-100 type-section row d-flex justify-content-center">
                
                <section class="col-lg-8 col-md-12 therapist-data" v-show="practitioner != {}">
                    <div class="therapist-basic-description d-flex">
                        <img :src="profileImage" alt="" class="profile-image">
                        <div class="text-part">
                            <h4 class="therapist-name">{{practitioner.full_name}}</h4>
                            <p><span v-for="provider_type in practitioner.provider_types" :key="provider_type.index">{{ provider_type }}</span></p>
                            <p>{{ practitioner.experience }}</p>
                        </div>
                    </div>
                    <div class="w-100 row short-description-block">
                        <div class="w-50 d-flex">
                            <div class="therapist-data-short-description">
                                <span><img class="therapist-data-short-description-img" src="../assets/images/star-icon.svg" alt="">{{ practitioner.reviews_rating }}</span>
                            </div>
                            <div class="therapist-data-short-description">
                                <span><img class="therapist-data-short-description-img" src="../assets/images/comment-icon.svg" alt="">{{ practitioner.reviews_total }}</span>
                            </div>
                            <div class="therapist-data-short-description" v-if="practitioner.min_fee != practitioner.max_fee">
                                <span><img class="therapist-data-short-description-img" src="../assets/images/tag-black.svg" alt="">£{{practitioner.min_fee}}-{{practitioner.max_fee}}</span>
                            </div>
                            <div class="therapist-data-short-description" v-else>
                                <span><img class="therapist-data-short-description-img" src="../assets/images/tag-black.svg" alt="">£{{practitioner.min_fee}}</span>
                            </div>
                        </div>
                        <div class="w-50 d-flex justify-content-end therapist-short-descripton-last-part">
                            <span v-if="!practitioner.works_with_insurance">
                                <img src="../assets/images/health-protect-icon.svg" alt="">Therapist works with insurance<img src="../assets/images/info-icon.svg" alt="">
                            </span>
                        </div>
                    </div>
                    <div class="w-100 therapist-data-short-description-wrapper-bottom">
                        <div class="therapist-data-short-description">
                            <span v-for="address in practitioner.addresses" :key="address.index"><img class="therapist-data-short-description-img" src="../assets/images/geo-position.svg" alt="">{{address}}</span>
                        </div>
                    </div>
                    
                    <!-- <hr style="margin-top: 24px; margin-bottom: 24px"> -->

                    <!-- <div class="available-session-wrapper">
                        <h5 class="availavble-session-title">Next available online sessions:</h5>
                        <div class="row chips-block">   
                            <div class="chip" v-for="availableTodayHours in practitioner.availableTodayHours" :key="availableTodayHours.index">
                                <p>Today {{availableTodayHours}}</p>
                            </div>
                        </div>
                    </div> -->

                    <!-- <hr style="margin-top: 24px; margin-bottom: 24px"> -->

                    <iframe v-if="practitioner.profile_video_link" :src="convertYouTubeURL(practitioner.profile_video_link)" frameborder="0" class="w-100 video-iframe"></iframe>
                    
                    <hr style="margin-bottom: 24px; margin-top: 30x">

                    <div class="detailed-info-block">
                        <h4>Experience</h4>

                        <div class="therapist-info">
                            <h6>Accreditation / Licence</h6>
                            <div>
                                <p>{{ practitioner.licence }}</p>
                                <p>License/registration No. {{practitioner.licence_number}}</p>
                            </div>
                        </div>
                        <div class="therapist-info">
                            <h6>About</h6>
                            <ShowMoreComponent v-if="practitioner.about" :fullText="practitioner.about" />
                        </div>
                        <div class="therapist-info" v-if="practitioner.insurance_certificates && practitioner.insurance_certificates.length > 0">
                            <h6>Insurance Certificate <img src="../assets/images/info-icon.svg" alt=""></h6>
                            <p>Expired on 31/03/2020</p>
                            <a href="">View Insurance certificate</a>
                        </div>
                        <div class="therapist-info">
                            <h6>Languages:</h6>
                            <div class="row chips-block" style="margin-top: 8px"> 
                                <div class="chip language-chip" v-for="language in practitioner.languages_list" :key="language.index">
                                    <p>{{language}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr style="margin-bottom: 24px; margin-top: 24x">

                    <div class="detailed-info-block">
                        <h4>Approaches</h4>
                        <div class="therapist-approach-block">
                            <h6 class="therapy-approach" v-for="therapy_approach in practitioner.therapist_approaches" :key="therapy_approach.index">
                                <img src="../assets/images/marked-gray.svg"> {{therapy_approach}}
                            </h6>
                        </div>

                        <div class="therapist-info" v-if="practitioner.issue && practitioner.issue.length > 0">
                            <h6>Specialization:</h6>
                            <div  style="margin-top: 8px"> 
                                <div v-if="showIssuesPartially" class="row chips-block">
                                    <div class="chip language-chip" style="margin-bottom: 8px" v-for="issue in practitioner.issue.slice(1, 5)" :key="issue.index">
                                        <p>{{issue.issue_name}}</p>
                                    </div>
                                    <p style="padding-left: 0px;" @click="showIssuesPartially = false">See all specializations <img src="../assets/images/arrow-down.svg"></p>
                                </div>
            
                                <div v-else class="row chips-block" >
                                    <div class="chip language-chip" style="margin-bottom: 8px" v-for="issue in practitioner.issue" :key="issue.index">
                                        <p>{{issue.issue_name}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <hr style="margin-bottom: 24px; margin-top: 24x">
                    
                    <div class="detailed-info-block">
                        <h4>Reviews</h4>
                        <div class="therapist-info" v-for="review in practitioner.reviews_list" :key="review.index">
                            <h6>{{ review.reviewer }}</h6>
                            <p>{{ review.comment }}</p>
                        </div>
                    </div>

                </section>
                
                <!-- <section class="col-4 d-md-none d-lg-block therapist-sidebar">
                    <section class="sidebar-exeternal-session">
                        <BookPractitionerCretiriaComponent></BookPractitionerCretiriaComponent>
                    </section>
                    
                </section>
                <div class="mobile-filter d-md-block d-lg-none">
                    <button class="filter-open-button" @click="showModal = true">Book session</button>
                </div>
                <BookPractitionerModalComponent :key="'modal-booking'" :isOpen="showModal" @close="showModal = false"></BookPractitionerModalComponent> -->
            </section>
      
      <!-- Add more information as needed -->
    </div>
</template>

<script>
    import 'vue-slider-component/theme/default.css'
    import '@vuepic/vue-datepicker/dist/main.css'
    import axios from '@/axios';
    import ShowMoreComponent from './Components/ShowMoreComponent.vue';
    // import BookPractitionerModalComponent from './Components/BookPractitionerModalComponent.vue';
    // import BookPractitionerCretiriaComponent from './Components/BookPractitionerCretiriaComponent.vue';
   

    export default{
        name: 'TherapistSearch',
        components: {
            ShowMoreComponent,
            // BookPractitionerModalComponent,
            // BookPractitionerCretiriaComponent
        },
        data() {
            return {
                selectedOption: 'online', // Default selected option
                startDate: this.getMonday(new Date()),
                selectedDate: "",
                selectedTime: "",
                days: [],
                times: ["9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00"],
                isDragging: false,
                startX: 0,
                lastScrollLeft: 0,
                practitionerId: null,
                practitioner: {},
                profileImage: '',
                therapistServices: {},
                service: null,
                showIssuesPartially: true,
                months: {
                    'Jan': 0,
                    'Feb': 1,
                    'Mar': 2,
                    'Apr': 3,
                    'May': 4,
                    'Jun': 5,
                    'Jul': 6,
                    'Aug': 7,
                    'Sep': 8,
                    'Oct': 9,
                    'Nov': 10,
                    'Dec': 11
                },
                customerId: null,
                showModal: false
            };
        },
        methods: {
            getMonday(d) {
                d = new Date(d);
                let day = d.getDay();
                let diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is Sunday
                return new Date(d.setDate(diff));
            },
            selectDate(date) {
                this.selectedDate = date;
            },
            moveSlider(direction) {
                this.startDate.setDate(this.startDate.getDate() + direction);
                this.generateDays();
            },
            generateDays() {
                this.days = [];
                const today = new Date().setHours(0, 0, 0, 0);
                for (let i = 0; i < 5; i++) {
                    const dateObj = new Date(this.startDate);
                    dateObj.setDate(this.startDate.getDate() + i);

                    const dayOptions = { weekday: "short" };
                    const dateOptions = { day: "numeric" };
                    const monthOptions = { month: "short" };
                    const generalDateOptions = { weekday: "short", day: "numeric", month: "short" };

                    const dayStr = new Intl.DateTimeFormat("en-US", dayOptions).format(dateObj);
                    const dateStr = new Intl.DateTimeFormat("en-US", dateOptions).format(dateObj);
                    const monthStr = new Intl.DateTimeFormat("en-US", monthOptions).format(dateObj)
                    const fullDateStr = new Intl.DateTimeFormat("en-US", generalDateOptions).format(dateObj);

                    this.days.push({
                        dayOfWeek: dayStr,
                        date: dateStr,
                        month: monthStr,
                        fullDate: fullDateStr,
                        past: dateObj < today,
                    });
                }
            },
            handleMouseDown(event) {
                event.preventDefault();
                this.isDragging = true;
                this.startX = event.clientX;
                this.lastScrollLeft = this.$refs.timeBlockContainer.scrollLeft;
            },
            handleMouseUp() {
                this.isDragging = false;
            },
            handleMouseMove(event) {
                if (!this.isDragging) return;
                const dx = this.startX - event.clientX;
                this.$refs.timeBlockContainer.scrollLeft = this.lastScrollLeft + dx;
            },
            selectTime(time){
                this.selectedTime = time;
            },
            async getProfileData(id){
                const response = await axios.get('/practitioner/'+id+'/profile');
                return response.data;
            },
            async getPractitionerAvailableHours(id){
                const response = await axios.get('/practitioner/'+id+'/next-available-sessions ');

                const currentDate = new Date();

                // 2. Извлекаем день и месяц из текущей даты
                const currentDay = currentDate.getDate();
                const currentMonth = currentDate.getMonth() + 1; // Месяцы в JavaScript начинаются с 0

                // 3. Фильтруем массив дат
                const filteredDates = response.data.free_datetime.filter(dateStr => {
                    const dateParts = dateStr.split(" ")[0].split("-"); // Разбиваем строку даты на части
                    const day = parseInt(dateParts[2], 10);
                    const month = parseInt(dateParts[1], 10);

                    return day === currentDay && month === currentMonth;
                });

                const availableTodayHours = filteredDates.map((date) => {
                    let timeParts = date.split(" ")[1].split(":");
                    timeParts.pop();

                    return timeParts.join(':');
                })

                return availableTodayHours; 
            },
            async getPractitionerServices(id){
                const response = await axios.get('/practitioner/'+id+'/service-types ');

                this.therapistServices = response.data;
            },
            convertYouTubeURL(url) {
                const match = url.match(/watch\?v=([a-zA-Z0-9_-]+)/);
                if (match && match[1]) {
                    return `https://www.youtube.com/embed/${match[1]}`;
                }
                return null; // или вернуть исходный URL, если не соответствует ожидаемому формату
            },
            async bookSession(){
                try {
                    await axios.post('/appointment/create-session', {
                            practitioner_id: this.practitioner.practitioner_id,
                            fee: this.service.fee,
                            booking_type: 'schedule'
                        }, 
                        {
                            headers: {
                                Authorization: `Bearer ${this.$store.state.accessToken}`
                            }
                        }).then(async (response) => { 
                            if(this.customerId != null){
                                this.payWithExistingCard(response.data);
                            }else{
                                this.$router.push({name:'/add-card', query: { 
                                    // therapistsData: response.data,
                                    session: response.data,
                                    service: this.service,
                                    process: "scheduled-booking"
                                  } 
                                });
                            }
                            
                        })
                } catch (error) {
                    this.$router.push('/');
                    console.error('Ошибка при получении данных от API:', error)
                }
            },
            createDateTime() {
                // Предполагается, что dateStr имеет формат 'Tue, Oct 31', а timeStr - '12:00'
                const dateParts = this.selectedDate.split(', ')[1].split(' '); // Разбиваем строку на части
                const timeParts = this.selectedTime.split(':'); // Разбиваем время на часы и минуты

                // Создаем новую дату для текущего года
                const dateTime = new Date(new Date().getFullYear(), this.months[dateParts[0]], dateParts[1], timeParts[0], timeParts[1]);
                
                return dateTime;
            },
            async getClientCards() {
                if(this.$store.state.user.isLoggedIn){
                    try {
                        const response = await axios.get('/payment/stripe/get-client-cards', {
                                headers: {
                                    Authorization: `Bearer ${this.$store.state.accessToken}`
                                }
                            });
                        if (response.data && response.data.length > 0) {
                            this.customerId = response.data[0].customer_id;
                        }
                    } catch (error) {
                        console.error('Произошла ошибка при получении карт клиента:', error);
                    }
                }
            },
            async payWithExistingCard(sessionData){
                try {
                    await axios.post('payment/stripe/existing-card-payment', {
                            session_id: sessionData.session_id,
                            fee_amount: this.service.fee,
                            customer_id: this.customerId
                        }, 
                        {
                            headers: {
                                Authorization: `Bearer ${this.$store.state.accessToken}`
                            }
                        }).then(() => { // использование стрелочной функции здесь
                            this.createInstantBooking(sessionData);
                        })
                } catch (error) {
                    this.$router.push('/');
                    console.error('Ошибка при получении данных от API:', error)
                }
            },
            async createInstantBooking(sessionData){
                try{
                    await axios.post('/appointment/instant-book', {
                        'practitioner_id': this.practitioner.practitioner_id,
                        'appointment_id': sessionData.appointment_id,
                        'patient_id': sessionData.patient_id,
                        'location_id': sessionData.location_id,
                        'appointment_type': sessionData.appointment_type,
                        'client_category_id': sessionData.client_category_id,
                        'datetime': this.createDateTime(),
                        'service_id': this.service.service_id,
                        'booking_type': "schedule",
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`
                        }
                    }).then(async () => {
                        this.$router.push('/my-sessions')
                    })
                } catch (error) {
                    this.$router.push('/');
                    console.error('Ошибка при получении данных от API:', error)
                }
                
            }
        },
        async mounted() {
            this.generateDays();
            this.practitionerId = this.$route.query.practitionerId;
            this.practitioner = await this.getProfileData(this.practitionerId);
            this.practitioner.availableTodayHours = await this.getPractitionerAvailableHours(this.practitioner.practitioner_id);
            this.profileImage = this.practitioner.main_image;
            this.getPractitionerAvailableHours(this.practitioner.practitioner_id);
            this.getPractitionerServices(this.practitioner.practitioner_id);
            this.getClientCards();
        },
    }
</script>

<style scoped>
  .dropdown-item{
    cursor: pointer;
  }

  .dropdown{
    position: absolute;
    z-index: 999;
    background: #fff;
    width: 100%;
    left: -2%;
    padding: 15px;
    border: 2px solid #F3F3F6;
    border-radius: 8px;
    height: 200px;
    overflow-y: scroll;
  }
  .dropdown-arrow{
    width: 18px;
    height: 18px;
    cursor: pointer;
  }


 .sidebar-exeternal-session{
    background: #fff;
    padding: 24px;
    width: 100%;
    border-radius: 16px;
 }

 .short-description-block{
    margin-left: 0px !important;
    margin-top: 24px;
 }

 .detailed-info-block > h4{
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #00809D !important;
    margin-bottom: 18px;
 }

 .therapist-info{
    margin-bottom: 18px;
    margin-left: 0px !important;
 }

 .therapist-approach-block{
    margin-bottom: 18px;
    margin-left: 0px;
 }

 .therapist-info > p{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
 }

 .therapist-info > div > p{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
 }

 .therapist-info > a{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
    color: #2F80ED;
 }

 .therapist-info > h6{
    font-size: 18px !important;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
 }

 .therapy-approach{
    font-size: 18px !important;
    font-weight: 400 !important;
 }  

 .therapy-approach img {
    margin-right: 6px !important;
    margin-left: 0px !important;
 }

 .therapist-info > h6 > img{
    margin-left: 4px;
 }

 .instagram-link{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    border: 1px solid #DADCE4;
    border-radius: 12px;
    text-decoration: none;
    margin-top: 13px;
 }

 .instagram-link-with-logo-block{
    align-items: center;
 }

 .instagram-link-with-logo-block p{
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 20px;
    margin-left: 8px;
 }

 .video-iframe{
    height: 384px;
    border-radius: 16px;
 }

 .availavble-session-title{
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
 }

 .dateText{
    font-size: 12px;
 }

 .therapist-data-short-description-wrapper-bottom{
    margin-top: 4px;
 }

 .therapist-short-descripton-last-part img:last-child{
    margin-left: 5px;
 }

 .therapist-short-descripton-last-part img:first-child{
    margin-right: 5px;
 }

 .short-description-block div{
    padding: 0px;
 }

 .therapist-data-short-description span{
    display: flex;
    align-items: center;
    font-size: 14px;
 }

 .therapist-data-short-description {
    margin-right: 24px;
 }

 .therapist-data-short-description-img {
    width: 12px;
    margin-right: 3px;
 }

 .type-section{
    margin-left: 0px;
 } 

 .back_button{
    width: 20px;
    height: 20px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px #3353C414;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
 }

 .breadcrumbs{
    padding: 18px 0px;
 }
 
 .breadcrumb-element{
    margin-left: 6px;
    margin-right: 6px;
    cursor: pointer;
 }
 .breadcrumb-element a{
    text-decoration: none;
    color: #13204E;
 }
 .therapist-data{
    background: #fff;
    padding: 32px;
    border-radius: 16px;
    margin-bottom: 32px;
 }
.therapist-basic-description{
    width: 100%;
    display: flex;
    align-items: center;
 }
.therapist-basic-description img{
    width: 136px;
    height: 136px;
 }
.therapist-name{
    font-size: 38px;
    font-family: Libre Franklin;
    font-weight: 600;
    line-height: 46px;
    margin-bottom: 0px;
 }
.text-part{
    margin-left: 24px;
 }
.text-part > p {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 22px;
 }

.therapist-sidebar{
    padding-left: 24px;
    padding-right: 0px;
 }

.chip{
    padding: 4px 12px 4px 12px;
    display: flex;
    width: fit-content;
    align-items: center;
    font-size: 12px;
    height: 28px;
    border-radius: 16px;
    margin-right: 8px;
    background: #fff;
 }

.chip.active {
    background: #43C9F3;
 }

.chip p{
    margin-bottom: 0px;
 }

.chip.active p {
    color: #fff;
 }

.chips-block{
    margin-left: 0px;
 }

.date-options-title{
    font-size: 12px;
    margin-top: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
 }

.language-chip{
    background: #737678 !important;
 }

.language-chip p {
    color: #fff;
 }

.toggle-slider.face_to_face {
    transform: translateX(calc(100%));
 }
  
  .toggle-switch-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
 }

  .type-description{
    margin-bottom: 32px;
 }

  .toggle-switch {
    position: relative;
    width: 370px;
    height: 38px;
    background-color: #F3F3F6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border-radius: 15px;
    border: 1px solid var(#E6E8ED);
 }

  .toggle-switch-internal {
    position: relative;
    width: 100%;
    height: 38px;
    background-color: #F3F3F6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border-radius: 15px;
    border: 1px solid var(#E6E8ED);
 }

  .toggle-option {
    cursor: pointer;
    z-index: 2;
    width:50%;
    text-align: center;
    transition: all 0.3s ease;
 }
  
  .toggle-option.active span {
    font-weight: bold;
 }

  .toggle-slider {
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(50% - 2px);
    height: calc(100% - 4px);
    background-color: #fff;
    border-radius: 15px;
    transition: all 0.3s ease;
    z-index: 1;
    box-shadow: 0px 3px 8px 0px #182D420D;
 }
  
  .toggle-slider.face_to_face {
    transform: translateX(calc(100%));
 }

  .sidebar_title{
    font-size: 24px;
    font-weight: bold;
 }

  .sidebar_sub_title{ 
    font-size: 16px;
    font-weight: bold;
 }

  .service-selection{
    background: #F3F3F6;
    padding: 8px 12px 8px 12px;
    min-height: 44px;
    border-radius: 8px;
    border: 1px solid#E6E8ED;
 }

  .additional-meeting-data{
    margin-top: 15px;
 }

  .day {
    padding: 10px;
    margin: 5px;
    border: 1px solid black;
 }
  
  .selected {
    background: linear-gradient(73.96deg, #43C9F3 10.17%, #3CAEE0 91.5%) !important;
    color: #fff !important;
 }

 .day_of_the_week{
    width: 36px;
    height: 36px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 20px;
    background: #F3F3F6;
    color: #6B7492;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
 }

 .day_of_the_week_block{
    width: 39px;
    padding: 0px;
    margin-left: 4px;
    margin-right: 6px;
 }

 .calender-switch-button{
    width: 6px;
    padding: 0px;
    background: transparent;
    border: none;
    margin-left: 1px;
    margin-right: 0px;
}

 .calender_block{
    padding: 24px;
 }

 .calender-row{
    margin-left: 0px;
    margin-right: 0px;
 }

 .days-row{
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    display: flex;
    width: fit-content;
 }

 #timeBlockContainer {
    display: flex;
    width: 100%;
    overflow: hidden;
    cursor: grab;
    white-space: nowrap;
    user-select: none;
    height: 28px;
    margin-top: 16px;
  }
  
  #timeBlockContainer:active {
    cursor: grabbing;
  }
  
  .timeBlock {
    width: 57px;
    height: 28px;
    padding: 4px 12px 4px 12px;
    border-radius: 15px;
    background: #F3F3F6;
    gap: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #6B7492;
    margin-right: 8px;
  }
  
  .profile-image{
    border-radius: 100px;
    border: 1px solid #E6E8ED;
  }

  .mobile-filter{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    border: 1px solid var(--main-colors-dark-10, #E6E8ED);
    background: var(--main-colors-white, #FFF);
    box-shadow: 0px -2px 4px 0px rgba(7, 20, 60, 0.05);
    height: 92px;
    padding: 24px 48px;
  }

  .filter-open-button{
    width: 100%;
    height: 44px;
    background: linear-gradient(90deg, #43C9F3 0%, #3CAEE0 100%);
    color: #fff;
    font-size: 17px;
    padding: 8px 0;
    line-height: 28px;
    border: 1px solid #43C9F3;
    border-radius: 22px;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
 }
</style>