<template>
  <section class="container h-100 d-flex align-items-center">
      <div class="w-100 d-flex align-items-center flex-column main-wrapper">
          <h1 class="text-center fw-bold">FAQ</h1>
          <div class="steps-container steps-wrapper-block w-100">
              <h5 class="fw-bold">How much does Therapy App cost?</h5>
              
              <p>Therapy App is a free marketplace. This means pricing is varied and determined by the practitioner. Typically more experienced practitioners charge more, but the wide selection of therapists mean you can find a practitioner regardless of your budget.</p>

              <h5 class="fw-bold">Which therapist should I choose?</h5>

              <p>Our algorhythm helps to match client and therapist based on suitability. We recommend you read the therapist profile to help decide who is the best match for you.</p>

              <h5 class="fw-bold">What if I don’t like my therapist?</h5>

              <p>Therapy App recommends therapists based on the filters selected, most appropriate clinical approach and availability. There is no obligation to stay with a therapist if you feel it is not working out. Simply return to the homepage, and search for a new therapist.</p>

              <h5 class="fw-bold">How do I cancel or reschedule my session?</h5>

              <p>Cancellations must be made either within 48 hours after booking a session, or 48 hours in advance of the session taking place. Do this within the App. </p>
              
              <h5 class="fw-bold">Are the sessions confidential?</h5>

              <p>Therapy App facilitates a therapeutic relationship between client and practitioner. We thoroughly screen and ensure practitioners on the platform are regulated by an authorised professional regulatory body, and as such are bound by an ethical and professional responsibility to respect confidentiality. Therapy App has no access to the contents of the consultations.</p>

              <h5 class="fw-bold">How is my data used?</h5>

              <p>Details of how Therapy App looks after your data can be found in our Privacy Policy</p>

              <h5 class="fw-bold">How old do I have to be to use Therapy App?</h5>

              <p>Each practitioner on Therapy App will decide the age groups they want to see. We do have therapists who specialise in Child Therapy, however in order to book a session the user of the app must be able to make a payment from their linked account.</p>

              <h5 class="fw-bold">What are the differences between practitioner qualifications?</h5>

              <p>We have Psychiatrists, Psychologists and Psychotherapist’s who registered with the following regulatory bodies.</p>

              <ul>
                  <li>RcPsych registered Psychiatrist,</li>
                  <li>HCPC registered Psychologist,</li>
                  <li>BABCP accredited CBT Therapist,</li>
                  <li>BACP or UKCP registered Counsellor</li>
              </ul>

              <h5 class="fw-bold">What conditions are typically seen?</h5>

              <p>Therapy App is designed for mild to severe mental health conditions, such as depression and anxiety, relationship issues, trauma, complex mental illness and much more, as well as coaching and personal development. </p>

              <h5 class="fw-bold">Is this an appropriate service if I’m feeling suicidal?</h5>

              <p>No. Therapy App is for use for mild to severe mental health conditions where someone is not at high risk of taking an action to end their life. If you are considering harming yourself or others, have made plans to, or have suicidal ideations or intentions, you must immediately contact the emergency services and notify the relevant authorities. In the UK call 999 if your life is in immediate danger, alternatively contact the Samaritans on 116 123. Make every effort to seek in person assistance. 
Therapy App is not designed for use with severe mental health conditions requiring immediate support due to suicidal ideation where someone may take an action in the near future or where someone has the intention of harming another person, nor is it a platform for medical emergencies. Should you receive advice through the app that you need to engage with a medical or other professional, do so immediately.</p>

              <h5 class="fw-bold">What conditions are typically seen?</h5>

              <p>Therapy App is designed for mild to severe mental health conditions, such as depression and anxiety, relationship issues, trauma, complex mental illness and much more, as well as coaching and personal development. </p>

                
              <h5 class="fw-bold">Why are sessions typically a between 50 minutes to hour long?</h5>

              <p>Therapy App is designed for mild to severe mental health conditions, such as depression and anxiety, relationship issues, trauma, complex mental illness and much more, as well as coaching and personal development. </p>

              <h5 class="fw-bold">How do I contact Therapy App or make a complaint?</h5>

              <p>[<a href="mailto:team@privatetherapyclinic.com">team@privatetherapyclinic.com</a>].</p>

              <p class="fst-italic">Last Updated: April 1, 2024</p>   
          </div>
      </div>
  </section>
</template>

<script>
export default {
name: 'FrequentlyAskedQuestions',
data() {
  return {
  }
}
}
</script>

<style scoped>

h1{
  font-size: 36px;
  margin-bottom: 12px;
}

h5 {
margin-bottom: 4px;
}

p{
margin-bottom: 24px;
}

.description-text {
  margin-bottom: 0px;
}

.main-wrapper{
  margin-top: 60px;
  margin-bottom: 60px;
}
.steps-list {
list-style: none;
padding: 0;
margin-bottom: 0px;
}

.step-item {
display: flex;
align-items: flex-start;
}

.step-number-container {
display: flex;
flex-direction: column;
align-items: center;
margin-right: 1rem;
}

.step-number {
padding: 12px;
color: var(--font-dark-100, #13204E);
display: flex;
align-items: center;
justify-content: center;
border-radius: 24px;
border: 1px solid var(--main-colors-dark-15, #DADCE4);
background: var(--MainColors-White, #FFF);
box-shadow: 0px 4px 8px 0px rgba(51, 83, 196, 0.08);
}

.step-number > span {
  width: 24px !important;
  height: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.step-line {
width: 2px;
height: 32px; /* Adjust the height as needed */
background-color: #43C9F3;
}

.step-content h3 {
margin: 0;
font-size: 1.1rem;
}

.step-content p {
margin: 0;
}

.content-text > h3{
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
}

.steps-wrapper-block{
  width: 612px;
  padding: 48px;
  background: #fff;
  border-radius: 16px;
  margin-bottom: 32px;
  margin-top: 32px;
}

.next-step-button{
  width: 311px;
  margin-top: 18px;
}

@media (max-width: 612px) { 
.steps-wrapper-block{
  width: calc(100% - 24px);
  padding: 24px;
}

h1{
  font-size: 24px;
}
}
</style>