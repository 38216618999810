<template>
    <section class="container h-100 d-flex flex-column justify-center">
        <div class="w-100 d-flex align-items-center flex-column main-wrapper">
            <h1 class="text-center fw-bold">Calendar</h1>
            <div class="personal-info-wrapper-block">
                <h2 style="font-size: 32px; margin-bottom: 32px" class="fw-bold">Select days and times for sessions with patients</h2>
                <h5 style="font-size: 20; font-weight: 400; margin-bottom: 32px;">Set up days and times when you typically want to see Patients. Patients can request sessions with you during these times. You can set your schedule for online or face-to-face sessions or both.</h5>
                
                <p style="font-size: 18px;">
                    <img style="margin-bottom: 4px;" src="../assets/images/info-icon.svg" alt=""> Once you set you can change it at any time. It's best to also sync up your personal calendar (we will only see your free and busy slots), this will help clients know what times you have available within your set hours. </p>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <button class="next-step-button" @click="showModal = true" style="width: 311px; margin-bottom: 80px">Start now</button>
        </div>
    </section>
    <TherapistCalendarModal 
            v-if="showModal" 
            :isOpen="showModal" 
            @close="showModal = false" 
        />
</template>

<script>
import TherapistCalendarModal from "./Components/TherapistCalendarModal.vue"

export default {
  name: 'TherapistCalendar',
  components: {
    TherapistCalendarModal
  },
  data() {
    return {
        showModal: false
    }
  }
}
</script>

<style scoped>

h1{
    font-size: 36px;
    margin-bottom: 12px;
}

h5 {
  margin-bottom: 4px;
}

p{
  margin-bottom: 24px;
}

.description-text {
    margin-bottom: 0px;
}

.personal-info-wrapper-block{
    width: 612px;
    padding: 48px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 32px;
    margin-top: 32px;
    border: 1px solid #E6E8ED;
}

.main-wrapper{
    margin-top: 60px;
    margin-bottom: 32px;
}
.steps-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0px;
}

.step-item {
  display: flex;
  align-items: flex-start;
}

.step-number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
}

.step-number {
  padding: 12px;
  color: var(--font-dark-100, #13204E);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  border: 1px solid var(--main-colors-dark-15, #DADCE4);
  background: var(--MainColors-White, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(51, 83, 196, 0.08);
}

.step-number > span {
    width: 24px !important;
    height: 24px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}

.step-line {
  width: 2px;
  height: 32px; /* Adjust the height as needed */
  background-color: #43C9F3;
}

.step-content h3 {
  margin: 0;
  font-size: 1.1rem;
}

.step-content p {
  margin: 0;
}

.content-text > h3{
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
}

.steps-wrapper-block{
    width: 612px;
    padding: 48px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 32px;
    margin-top: 32px;
}

.next-step-button{
    width: 311px;
    margin-top: 18px;
}

@media (max-width: 612px) { 
  .steps-wrapper-block{
    width: calc(100% - 24px);
    padding: 24px;
  }

  h1{
    font-size: 24px;
  }
}
</style>