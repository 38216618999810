<template>
    <div class="info-block">
      <p v-if="showLess">{{ truncatedText }} <span class="show-span" @click="toggleShow">{{ showButtonText }}</span></p>
      <p v-else>{{ fullText }} <span class="show-span" @click="toggleShow">{{ showButtonText }}</span></p>
    </div>
</template>
  
  <script>
  export default {
    props: {
      fullText: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        showLess: true,
        truncatedText: '',
      };
    },
    computed: {
      showButtonText() {
        return this.showLess ? 'show more' : 'show less';
      },
    },
    methods: {
      truncate(text, length) {
        if (text.length <= length) return text;
        return text.substring(0, length) + '...';
      },
      toggleShow() {
        this.showLess = !this.showLess;
      },
    },
    created() {
      this.truncatedText = this.truncate(this.fullText, 200); // 50 символов, например
    },
  };
  </script>

  <style scoped lang="scss" rel="stylesheet/scss">
    .info-block{
        display: flex;
    }

    .show-span{
        font-style: italic;
        cursor: pointer;
        text-decoration: underline;
    }
  </style>