<template>
  <div class="d-flex flex-column h-100">
    <HeaderComponent :key="routerFullPath"/>

    <main class="flex-fill">
      <router-view></router-view>
    </main>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  },
  computed: {
    routerFullPath() {
      return this.$route.fullPath;
    }
  }
}
</script>
