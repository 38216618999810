<template>
    <section class="container" style="padding-top: 40px; padding-bottom: 80px;">
        <div class="w-100 d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-end"> 
                <h1 class="fw-bold" style="font-size: 32px">Calendar</h1>  
                <span style="margin-left: 12px; margin-bottom: 5px; font-size: 20px;" class="fw-bold">(Repeats weekly)</span>
            </div>
            <div>
                <span style="font-size: 18px; line-height: 25.2px; cursor: pointer" class="fw-bold" @click="requestGoogleCalendarAccess">
                    <img src="../assets/images/edit-red-icon.svg" style="margin-right: 4px; margin-bottom: 4px;">Connect calendar app
                </span>
            </div>
        </div>
        <div class="d-flex w-100" style="margin-top: 32px;">
            <div class="w-50 d-flex justify-content-end" style="padding-right: 12px">
                <div class="small-wrapper-standart-block" style="width: 440px; height: fit-content">
                    <div>
                        <div class="d-flex w-100 justify-content-between" style="padding-bottom: 24px;">
                            <div v-for="(weekName, index) in weekNames" :key="index">
                                <div class="week-name-item fw-bold" :class="{ active_week_name: weekName == selectedWeekName }" @click="selectWeekName(weekName)">{{ weekName }}</div>
                            </div>
                        </div>
                    </div>
                    <p class="fw-bold" style="font-size: 18px; margin-bottom: 12px">Type of sessions:</p>
                    <div class="toggle-switch-internal mb-3" v-if="schedules">
                        <div class="toggle-option" :class="{ active: selectedOption === 'online' }" @click="selectedOption = 'online'">
                            <span>Online</span>
                        </div>
                        <div class="toggle-option" :class="{ active: selectedOption === 'face_to_face' }" @click="selectedOption = 'face_to_face'">
                            <span>Face-to-face</span>
                        </div>
                        <div class="toggle-slider" :class="{ 'face_to_face': selectedOption === 'face_to_face' }"></div>
                    </div>
                    <p class="fw-bold" style="font-size: 18px; margin-bottom: 12px">Work schedule:</p>

                    <div class="d-flex w-100" style="margin-bottom: 8px;">
                        <div class="w-50" style="padding-right: 8px;">
                            <span class="fw-bold" style="font-size: 20px">From:</span>
                            <select name="" id="" v-model="fromValue" class="w-100 time-select">
                                <option :value="time.index" v-for="(time, index) in minValues" :key="index">
                                    {{ time.value }}
                                </option>
                            </select>
                        </div>
                        <div class="w-50" style="padding-left: 8px;">
                            <span class="fw-bold" style="font-size: 20px">To:</span>
                            <select name="" id="" v-model="toValue" class="w-100 time-select" style="">
                                <option :value="time.index" v-for="(time, index) in maxValues" :key="index">
                                    {{ time.value }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-50" style="padding-left: 12px;">
                <div class="small-wrapper-standart-block w-100" v-if="selectedOption == 'online'" style="width: 440px !important; height: fit-content">
                    <p class="fw-bold" style="font-size: 18px; margin-bottom: 12px">Work timeslots:</p>

                    <div style="margin-bottom: 16px">   
                        <span class="fw-bold" @click="selectAllTimeSlotsForCurrentDayAndOption()" style="color: #2F80ED; font-size: 16px; cursor: pointer">Select all timeslots</span>
                    </div>

                    <div v-if="schedules != null">
                        <div class="timeslot" v-for="timeslot in finalValues" :key="timeslot.index" style="margin-bottom: 16px;">
                            <div class="d-flex justify-content-between" style="margin-bottom: 8px;">   
                                <span style="font-size: 20px; font-weight: 700; line-height: 28px; color: #313333">{{ timeslot.valueFullName }}</span>
                                <div class="gender-checkbox">
                                    <input 
                                        type="checkbox" 
                                        class="gender-input" 
                                        :id="`provider-checkbox-${timeslot.index}`" 
                                        :checked="isChecked(timeslot.value)" 
                                        @change="updateTimeslot(timeslot, $event.target.checked)"
                                    >
                                    <label :for="`provider-checkbox-${timeslot.index}`" style="color: #fff">.</label>
                                </div>
                            </div>
                            <hr>
                        </div>
                    </div>
                </div>
                <div class="small-wrapper-standart-block w-100" v-else style="width: 440px !important; height: fit-content">
                    <p class="fw-bold" style="font-size: 18px; margin-bottom: 12px">Work timeslots:</p>

                    <div style="margin-bottom: 16px">   
                        <span class="fw-bold" @click="selectAllTimeSlotsForCurrentDayAndOption()" style="color: #2F80ED; font-size: 16px; cursor: pointer">Select all timeslots</span>
                    </div>

                    <div v-if="schedules != null">
                        <div class="timeslot" v-for="timeslot in finalValues" :key="timeslot.index" style="margin-bottom: 16px;">
                            <div class="d-flex justify-content-between" style="margin-bottom: 8px;">   
                                <span style="font-size: 20px; font-weight: 700; line-height: 28px; color: #313333">{{ timeslot.valueFullName }}</span>
                                <div class="gender-checkbox">
                                    <input 
                                        type="checkbox" 
                                        class="gender-input" 
                                        :id="`provider-checkbox-${timeslot.index}`" 
                                        :checked="isChecked(timeslot.value)" 
                                        @change="updateTimeslot(timeslot, $event.target.checked)"
                                    >
                                    <label :for="`provider-checkbox-${timeslot.index}`" style="color: #fff">.</label>
                                </div>
                            </div>
                            <hr>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </section>
</template>

<script>
import axios from '@/axios';

export default{
    data(){
        return {
            schedules: null,
            selectedOption: "online",
            selectedWeekName: "Mon",
            fromValue: 10,
            toValue: 19,
            weekNames: ["Mon", "Tue", "Wed", "Thr", "Fri", "Sut", "Sun"],
            times: [
                {
                    index: 8,
                    value: "08:00 AM",
                    valueFullName: "08:00 — 09:00"
                },
                {
                    index: 9,
                    value: "09:00 AM",
                    valueFullName: "09:00 — 10:00"
                },
                {
                    index: 10,
                    value: "10:00 AM",
                    valueFullName: "10:00 — 11:00"
                },
                {
                    index: 11,
                    value: "11:00 AM",
                    valueFullName: "11:00 — 12:00"
                },
                {
                    index: 12,
                    value: "12:00 PM",
                    valueFullName: "12:00 — 13:00"
                },
                {
                    index: 13,
                    value: "13:00 PM",
                    valueFullName: "13:00 — 14:00"
                },
                {
                    index: 14,
                    value: "14:00 PM",
                    valueFullName: "14:00 — 15:00"
                },
                {
                    index: 15,
                    value: "15:00 PM",
                    valueFullName: "15:00 — 16:00"
                },
                {
                    index: 16,
                    value: "16:00 PM",
                    valueFullName: "16:00 — 17:00"
                },
                {
                    index: 17,
                    value: "17:00 PM",
                    valueFullName: "17:00 — 18:00"
                },
                {
                    index: 18,
                    value: "18:00 PM",
                    valueFullName: "18:00 — 19:00"
                },
                {
                    index: 19,
                    value: "19:00 PM",
                    valueFullName: "19:00 — 20:00"
                },
                {
                    index: 20,
                    value: "20:00 PM",
                    valueFullName: "20:00 — 21:00"
                },
                {
                    index: 21,
                    value: "21:00 PM",
                    valueFullName: "21:00 — 22:00"
                },
                {
                    index: 22,
                    value: "22:00 PM",
                    valueFullName: null
                }
            ],
        }
    },
    methods: {
        async getCalendar(){
            await axios.get('/therapist/get-calendar', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                this.schedules = response.data;
            });
        },
        selectWeekName(weekName){
            this.selectedWeekName = weekName;
        },
        duplicateFromOnline(){
            const currentScheduleValue = this.schedules.find((schedule) => schedule.week_name == this.selectedWeekName);

            currentScheduleValue.face_to_face = currentScheduleValue.online;
        },
        selectAllTimeSlotsForCurrentDayAndOption(){
            const currentScheduleValue = this.schedules.find((schedule) => schedule.week_name == this.selectedWeekName);

            this.finalValues.forEach((timeslotValue) => {
                currentScheduleValue[this.selectedOption].push({timeslot: timeslotValue.value, location_id: null});
            });
        },
        isChecked(value) {
            const currentScheduleValue = this.schedules.find((schedule) => schedule.week_name == this.selectedWeekName);
        
            const convertedValue = this.convertTimeFormat(value);
            // Проверяем, содержит ли текущий выбор timeslot заданный value
            return currentScheduleValue[this.selectedOption].some(item => item.timeslot === convertedValue);
        },
        convertTimeFormat(time) {
            // Удаление AM/PM, так как оно не требуется для конечного формата
            let [hours, minutes] = time.split(':');

            // Проверка и корректировка формата часов, если он содержит PM или AM
            const period = minutes.slice(3);
            if (period.toLowerCase() === 'pm' && parseInt(hours) < 12) {
                hours = (parseInt(hours) + 12).toString();
            } else if (period.toLowerCase() === 'am' && parseInt(hours) === 12) {
                hours = '00';
            }

            minutes = minutes.slice(0, 2); // Очистка минут от AM/PM

            // Формирование и возвращение результата
            return `${hours} : ${minutes}`;
        },
        updateTimeslot(timeslot, checked) {
            const currentScheduleValue = this.schedules.find((schedule) => schedule.week_name == this.selectedWeekName);

            const convertedTimeslot = this.convertTimeFormat(timeslot.value);

            if (checked) {
                // Добавляем timeslot к выбранному массиву, если checkbox отмечен
                currentScheduleValue[this.selectedOption].push({ timeslot: convertedTimeslot, location_id: null });
            } else {
                // Удаляем timeslot из выбранного массива, если checkbox снят
                const index = currentScheduleValue[this.selectedOption].findIndex(item => item.timeslot === convertedTimeslot);
                if (index !== -1) currentScheduleValue[this.selectedOption].splice(index, 1);
            }
        },
        updateLocation(timeslot, locationId) {
            const currentScheduleValue = this.schedules.find((schedule) => schedule.week_name == this.selectedWeekName);
            // Обновляем location_id для выбранного timeslot
            
            const convertedTimeslot = this.convertTimeFormat(timeslot.value);

            const item = currentScheduleValue[this.selectedOption].find(item => item.timeslot === convertedTimeslot);
            if (item) item.location_id = locationId;
        },
        async requestGoogleCalendarAccess(){
            await axios.get('/therapist/request-google-calendar-access', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                window.open(response.data, '_blank');
            });
        }
    },
    computed: {
        // Фильтрованные значения для минимального select
        minValues() {
            // Если maxValue не выбран, возвращаем все значения
            if (this.toValue === null) {
                return this.times;
            }
            // Возвращаем значения меньше или равные выбранному maxValue
            return this.times.filter(value => value.index <= this.toValue);
        },
        // Фильтрованные значения для максимального select
        maxValues() {
            // Если minValue не выбран, возвращаем все значения
            if (this.fromValue === null) {
                return this.times;
            }
            // Возвращаем значения больше или равные выбранному minValue
            return this.times.filter(value => value.index >= this.fromValue);
        },
        finalValues(){
            if(this.fromValue === null || this.toValue === null) {
                return this.times;
            }

            const timeslots = this.times.filter(value => value.index >= this.fromValue && value.index <= this.toValue);

            timeslots.splice(timeslots.length-1, 1);

            return timeslots
        },
        currentSelectedSchedule(){
            return this.schedules.find(schedule => schedule.week_name == this.selectedWeekName);
        },
    },
    async mounted(){
        await this.getCalendar();
        this.schedules.forEach((schedule) => {
            schedule.online = schedule.online.filter((item) => {
                return item.timeslot !== '1 : 00'
            });
            schedule.face_to_face = schedule.face_to_face.filter((item) => item.timeslot !== '1 : 00' || item.timeslot !== '0 : 00');
        })

        this.weekNames.forEach((weekName) => {
            const foundProperty = this.schedules.find((item) => item.week_name == weekName);

            if(foundProperty == undefined) {
                this.schedules.push({
                    week_name: weekName,
                    online: [],
                    face_to_face: [],
                })
            }
        })
    }
}
</script>

<style>
    .active_week_name{
        background: #000;
        color: #fff;
        border-radius: 4px;
    }

    .small-wrapper-standart-block{
        background: #fff;
        padding: 24px;
        border-radius: 16px;
    }

    .week-name-item{
        width: 48px;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;
    }

    .time-select{
        background: #fff; 
        padding: 10px 28px 10px 28px; 
        border-radius: 8px; 
        border: 1px solid #E8E9EA;
        font-size: 18px;
        margin-top: 4px;
    }

    .gender-checkbox {
        position: relative;
        margin: 0px;
    }

    .gender-checkbox input[type="checkbox"] {
        display: none;
    }

    .gender-checkbox label {
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        line-height: 20px;
    }
    
    .gender-checkbox label:before {
        content: '';
        position: absolute;
        left: 0;
        top: -1;
        width: 18px;
        height: 18px;
        border: 1px solid #B5BAC9;
        border-radius: 8px;
    }
    
    .gender-checkbox input[type="checkbox"]:checked + label:before {
        background-color: #fff;
        border-color: #13204E;
    }
    
    .gender-checkbox input[type="checkbox"]:checked + label:after {
        content: '';
        position: absolute;
        width:10px;
        height: 10px;
        left: 4px;
        top: 4px;
        color: white;
        font-size: 16px;
        background: #13204E;
        border-radius: 16px;
    }
</style>