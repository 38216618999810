<template>
    <section class="container h-100 d-flex align-items-center">
        <div class="w-100 d-flex align-items-center flex-column main-wrapper">
            <div class="w-100 content-block-wrapper">
                <div class="d-flex align-items-center">
                    <div class="back-arrow-block">
                        <a href="">
                            <img src="../assets/images/back-arrow-button.svg" alt="">
                        </a>
                    </div>
                    <div class="progress-container">
                        <div class="steps-container">
                            <div class="steps">
                                <div class="step"><span>1</span></div>
                                <img src="../assets/images/separation-dot.svg" alt="">
                                <div class="step"><span>2</span></div>
                                <img src="../assets/images/separation-dot.svg" alt="">
                                
                                <div class="step active"><span>3</span></div>
                                <div class="d-flex flex-column align-items-center progress-content-block">
                                    <span>Service info</span>
                                    <div class="progress-bar-wrapper">
                                        <div class="progress-bar" :style="{ width: progress + '%' }"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        
                </div>
            </div>

            <div v-if="profileDataStep == 1" class="personal-info-wrapper-block">
                <h2>Select Therapy approaches</h2>

                <h3 class="sub-title">1/2. Choose the formats of sessions:</h3>

                <div>
                    <div class="custom-checkbox">
                        <input type="checkbox" id="provider-checkbox-online" v-model="selectedTherapyFormats" value="online">
                        <label for="provider-checkbox-online">Online</label>
                    </div>
                    <div class="custom-checkbox">
                        <input type="checkbox" id="provider-checkbox-face-to-face" v-model="selectedTherapyFormats" value="face-to-face">
                        <label for="provider-checkbox-face-to-face">Face-to-face</label>
                    </div>
                </div>

                <h3 class="sub-title">1/2. Choose the formats of sessions:</h3>

                <div v-for="service in servicesList" :key="service.service_id">
                    <div v-if="service.active" class="selected-service-block d-flex align-items-flex-start" style="margin-bottom: 16px">
                        <div style="margin-right: 10px">
                            <button class="selected-service-button" @click="unselectService(service)">
                                <img src="../assets/images/selected.svg" alt="">
                            </button>
                        </div>
                        <div style="flex-grow: 1;">
                            <p style="margin-bottom: 6px">
                                Service
                            </p>
                            <h4 style="margin-bottom: 0px; font-size: 20px; font-weight: 600">
                                {{ service.name }}
                            </h4>

                            <div>
                                <div style="margin-top: 24px">
                                    <div class="d-flex justify-content-between">
                                        <p class="slider-paragraph">Duration</p>
                                        <p class="slider-paragraph">{{service.duration}} min</p>
                                    </div>
                                    <vue-slider 
                                        v-model="service.duration" 
                                        :min="0" 
                                        :max="240" 
                                        :tooltip="'never'" 
                                        :dotStyle="{backgroundColor: '#43C9F3', width: '16px', height:'16px', borderRadius: '10px', boxShadow: 'none'}"
                                        :processStyle="{backgroundColor: '#43C9F3'}"
                                        :railStyle="{backgroundColor: '#B5BAC9', borderRadius: '16px'}"
                                    />
                                </div>
                                <div style="margin-top: 24px">
                                    <div class="d-flex justify-content-between">
                                        <p class="slider-paragraph">Cost</p>
                                        <p class="slider-paragraph">£{{service.fee}}/session</p>
                                    </div>
                                    <vue-slider 
                                        v-model="service.fee" 
                                        :min="0" 
                                        :max="1000" 
                                        :tooltip="'never'" 
                                        :dotStyle="{backgroundColor: '#43C9F3', width: '16px', height:'16px', borderRadius: '10px', boxShadow: 'none'}"
                                        :processStyle="{backgroundColor: '#43C9F3'}"
                                        :railStyle="{backgroundColor: '#B5BAC9', borderRadius: '16px'}"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="unselected-service-block d-flex align-items-flex-start" style="margin-bottom: 16px">
                        <div style="margin-right: 10px">
                            <button class="unselected-service-button" @click="selectService(service)"></button>
                        </div>
                        <div>
                            <p style="margin-bottom: 6px">
                                Service
                            </p>
                            <h4 style="margin-bottom: 0px; font-size: 20px; font-weight: 600">
                                {{ service.name }}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="profileDataStep == 2" class="personal-info-wrapper-block">
                <h2>Location for face-to-face sessions</h2>


                <div v-for="(location, index) in locations" :key="index" style="margin-top: 32px">
                    <div class="w-100 d-flex justify-content-between">
                        <p>Location {{ index+1 }}</p>
                        <img @click="deleteLocation(index)" src="../assets/images/filled-cross.svg" alt="">
                    </div>

                    <div>
                        <h3 class="sub-title">Country</h3>
                        <input v-model="location.country" type="text" class="sign-field w-100"  placeholder="Country">
                        
                        <h3 class="sub-title">City</h3>
                        <input v-model="location.city" type="text" class="sign-field w-100"  placeholder="City">

                        <h3 class="sub-title">Street</h3>
                        <input v-model="location.street" type="text" class="sign-field w-100"  placeholder="Street">

                        <h3 class="sub-title">Postal code</h3>
                        <input v-model="location.postal_code" type="text" class="sign-field w-100"  placeholder="Postal code">
                    </div>
                </div>

                <div class="d-flex align-items-center justify-content-center">
                    <div class="w-100 d-flex justify-content-center" style="margin-top: 24px">
                        <button @click="addNewLocation" role="button" for="certificate-upload" class="upload-document-button d-flex align-items-center"><img src="../assets/images/plus.svg" alt="">Add other location</button>
                    </div>
                </div>

            </div>

            <div v-if="profileDataStep == 3" class="personal-info-wrapper-block">
                <h2>Sessions with insurance</h2>

                <p style="margin-bottom: 32px">Are you interested in sessions with payment from insurance companies? </p>

                <div class="d-flex align-items-center">
                    <p style="margin-bottom: 0px">
                        <input id="normal-toggle" type="checkbox" class="itoggle" v-model="insuranceSessions"/>
                        <label for="normal-toggle"></label>
                    </p>
                    <p style="margin-bottom: 0px; margin-left: 11px">{{ insuranceSessions ? "Active" : "Non-active" }}</p>
                </div>
                

            </div>

            <div v-if="profileDataStep == 3 && insuranceSessions" class="personal-info-wrapper-block">
                <h3 class="sub-title">Choose your insurance companies</h3>

                <div class="chip-container">
                    <div class="chip-list row justify-content-between align-center">
                        <div class="row">
                        <div class="chip" v-for="(chip, index) in selectedInsuranceCompanies" :key="index">
                            <p>{{ chip.insurance_company }}</p>
                            <img class="chip-close-button" style="margin-left: 5px;" src="../assets/images/delete-chip-black.svg" @click="removeInsurenceCompany(index)" alt="">
                        </div>
                        </div>
                        
                        <div class="dropdown-arrow">
                        <img src="../assets/images/arrow-down-dark.svg" @click="showInsuranceCompaniesDropdown = !showInsuranceCompaniesDropdown">
                        </div>
                    </div>
                    <div class="dropdown" v-if="showInsuranceCompaniesDropdown">
                        <div class="dropdown-item" v-for="(item, index) in insuranceCompanies" :key="index" @click="addInsuranceCompany(item)">
                        {{ item.insurance_company }}
                        </div>
                    </div>
                </div>
            </div>

            <button class="next-step-button" @click="goToNextStep" style="margin-bottom: 80px;">Next step</button>
        </div>
    </section>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import axios from '@/axios';

    export default{
        components: {
          VueSlider
        },
        data(){
            return {
                insuranceCompanies: [
                    {
                        insurance_company: "Aviva"
                    }
                ],
                showInsuranceCompaniesDropdown: false,
                selectedInsuranceCompanies: [],
                insuranceSessions: false,
                selectedTherapyFormats: [],
                profileDataStep: 1,
                progress: 100,
                servicesList: [],
                locations: [
                    {
                        country: null,
                        city: null,
                        street: null,
                        postal_code: null,
                        latitude: null,
                        longitude: null,
                        home_number: null,
                        id: null
                    }
                ]
            }
        },
        async mounted(){
            if (typeof this.$route.query.isEditing !== 'undefined') {
                this.isEditing = this.$route.query.isEditing;
            }

            await axios.get('/therapist/services', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                this.servicesList = response.data;
            });
            await axios.get('/therapist/get-insurance-company-list', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => { 
                    this.insuranceCompanies = response.data;
            })

            await axios.get('/therapist/get-services', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                this.setServicesStatuses(this.servicesList);
                if (response.data.online.length > 0) {
                    this.servicesList.forEach(service => {
                        const selectedService = response.data.online.find(selectedService => {
                            return selectedService.service_id === service.service_id;
                        });

                        if (selectedService) {
                            service.active = true;
                            service.duration = selectedService.duration;
                            service.fee = selectedService.fee;
                        } else {
                            service.active = false; // Сделать сервис неактивным, если он не найден в online
                        }
                    });
                } else if(response.data.face_to_face.length > 0) {
                    this.servicesList.forEach(service => {
                        const selectedService = response.data.face_to_face.find(selectedService => {
                            return selectedService.service_id === service.service_id;
                        });

                        if (selectedService) {
                            service.active = true;
                            service.duration = selectedService.duration;
                            service.fee = selectedService.fee;
                        } else {
                            service.active = false; // Сделать сервис неактивным, если он не найден в online
                        }
                    });
                }
            });

            await axios.get('/therapist/get-locations', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => { 
                    response.data.forEach((location, index) => {
                        this.locations[index] = {
                            country: location.country,
                            city: location.city,
                            street: location.street,
                            postal_code: location.postal_code,
                            latitude: location.latitude,
                            longitude: location.longitude,
                            home_number: null,
                            id: location.id
                        } 
                    })
            })
        },
        methods: {
            addInsuranceCompany(item) {
                if(this.selectedInsuranceCompanies.indexOf(item) == -1){
                this.selectedInsuranceCompanies.push(item);
                this.showInsuranceCompaniesDropdown = false;
                }
            },
            removeInsurenceCompany(index) {
                this.selectedInsuranceCompanies.splice(index, 1);
            },
            async goToNextStep(){
                switch (this.profileDataStep) {
                    case 1:
                        await this.firstStepProcess()
                        break;
                    case 2: 
                        await this.secondStepProcess()
                        break;
                    case 3: 
                        await this.thirdStepProcess()
                        if(this.isEditing){
                            this.$router.push('/therapist/profile-view');
                        }else{
                            this.$router.push('/therapist/profile-preview')
                        }
                        break;
                    default:
                        break;
                }
                await this.getTherapistData();
                this.profileDataStep += 1;
            },
            async getTherapistData(){
                await axios.get(`/therapist/${this.$store.state.therapist.id}/get-profile`).then(async (response) => {
                    this.therapistData = await response.data;
                })
            },
            async firstStepProcess(){
                let selectedSortedValues = {};

                this.selectedTherapyFormats.forEach((format) => {
                    selectedSortedValues[format] = [];

                    const filteredServices = this.servicesList.filter((item) => item.active == true);

                    if (filteredServices.length > 0) {
                        selectedSortedValues[format] = filteredServices;
                    }
                });

                await axios.post('/therapist/add-service-list', {
                    ...selectedSortedValues
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    }
                });
            },
            async secondStepProcess(){
                await axios.post('/therapist/add-location-list', {
                    location_list: JSON.stringify(this.locations)
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    }
                });
            },
            async thirdStepProcess(){
                const insuranceCompaniesIds = this.selectedInsuranceCompanies.map((item) => {return item.insurance_company_id});

                await axios.post('/therapist/add-insurance-company-list', {
                    insurance_company_list: insuranceCompaniesIds
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    }
                });
            },
            setServicesStatuses(services) {
                return services.forEach((service) => {
                    service.active = false;
                    service.fee = 160;
                    service.duration = 120;
                });
            },
            selectService(service){
                service.active = true;
            },
            unselectService(service){
                service.active = false;
            },
            addNewLocation(){
                this.locations.push({
                    country: null,
                    city: null,
                    street: null,
                    postal_code: null,
                    latitude: null,
                    longitude: null,
                    home_number: null,
                    id: null
                })
            },
            deleteLocation(index){
                this.locations.splice(index, 1)
            },
        }
    }
</script>

<style scoped>

.chip-container{
    position: relative;
}

.chip-list{
    background: #fff;
    padding: 8px 12px 8px 12px;
    min-height: 44px;
    border-radius: 8px;
    margin-left: 0px;
    margin-right: 0px;
}

.chip > p{
    margin-bottom: 0px;
}


.dropdown-item{
    cursor: pointer;
  }

  .dropdown{
    position: absolute;
    z-index: 999;
    background: #fff;
    width: 100%;
    left: 0%;
    padding: 15px;
    border: 2px solid #F3F3F6;
    border-radius: 8px;
    height: 200px;
    overflow-y: scroll;
  }
  .dropdown-arrow{
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

.slider-paragraph{
    font-size: 16px;
    font-weight: 600;
}
.unselected-service-button{
    background: transparent;
    width: 24px;
    height: 24px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    border: 1px solid #B5BAC9;
    border-radius: 4px;
    margin: 0px 5px 5px 5px;
}

.selected-service-button{
    border: none;
    background: transparent;
}

.selected-service-block{
    border: 1px solid #43C9F3;
    padding: 16px;
    border-radius: 16px;
}

.unselected-service-block{
    background: #fff;
    border: 1px solid #DADCE4;
    padding: 16px;
    border-radius: 16px;
}

.gender-checkbox {
    position: relative;
    margin: 10px 0;
  }

.gender-checkbox input[type="radio"] {
    display: none;
}

.gender-checkbox label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
  }
  
  .gender-checkbox label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #B5BAC9;
    border-radius: 8px;
  }
  
  .gender-checkbox input[type="radio"]:checked + label:before {
    background-color: #fff;
    border-color: #13204E;
  }
  
  .gender-checkbox input[type="radio"]:checked + label:after {
    content: '';
    position: absolute;
    width:10px;
    height: 10px;
    left: 4px;
    top: 4px;
    color: white;
    font-size: 16px;
    background: #13204E;
    border-radius: 16px;
  }

.sub-title{
    font-size: 16px;
    font-weight: 600;
    margin-top: 32px;
}

.bio-field{
    background: #F3F3F6;
    border: 1px solid #E6E8ED;
    border-radius: 8px;
    width: 100%;
    padding: 10px 12px;
}
.personal-info-wrapper-block{
    width: 612px;
    padding: 48px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 32px;
    margin-top: 32px;
    border: 1px solid #E6E8ED;
}

.personal-info-wrapper-block > h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}

.content-block-wrapper{
    padding: 18px 24px 18px 24px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 32px;
    border: 1px solid #E6E8ED;
    margin-top: 24px;
}

.next-step-button{
    width: 311px;
    margin-top: 18px;
}

.sign-field{
    background: #fff !important;
    height: 44px;
    border: 1px solid #E6E8ED !important;
    border-radius: 8px;
    padding: 10px 12px 10px 12px;
    line-height: 24px;
}



.phone-codes-select{
    width: 152px;
    background: #F3F3F6;
    height: 44px;
    border: 1px solid #E6E8ED;
    border-radius: 8px;
    padding: 10px 40px 10px 12px;
    -webkit-appearance: none; /* Удалить стандартную стрелку в WebKit-браузерах */
    -moz-appearance: none;    /* Удалить стандартную стрелку в Firefox */
    appearance: none;         /* Стандартный способ, который может не поддерживаться всеми браузерами */
    background-image: url('../assets/images/arrow-down.svg'); /* Путь к вашей кастомной стрелке */
    background-repeat: no-repeat;
    background-position: right 16px center; /* Сдвигаем стрелку на 10px от правого края */
}

.timezones-select{
    width: 100%;
    background: #F3F3F6;
    height: 44px;
    border: 1px solid #E6E8ED;
    border-radius: 8px;
    padding: 10px 40px 10px 12px;
    -webkit-appearance: none; /* Удалить стандартную стрелку в WebKit-браузерах */
    -moz-appearance: none;    /* Удалить стандартную стрелку в Firefox */
    appearance: none;         /* Стандартный способ, который может не поддерживаться всеми браузерами */
    background-image: url('../assets/images/arrow-down.svg'); /* Путь к вашей кастомной стрелке */
    background-repeat: no-repeat;
    background-position: right 16px center; /* Сдвигаем стрелку на 10px от правого края */
}

.sign-field{
    background: #F3F3F6;
    height: 44px;
    border: 1px solid #E6E8ED;
    border-radius: 8px;
    padding: 10px 12px 10px 12px;
    line-height: 24px;
    width: 350px;
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 64px);
}

.progress-bar {
  height: 100%;
  background-color: #43C9F3;
  border-radius: 5px 0 0 5px; /* Rounded corners on the left side */
}

.progress-bar-wrapper {
  height: 4px;
  background-color: #E6E8ED;
  border-radius: 5px 0 0 5px; /* Rounded corners on the left side */
  width: 127px;
}

.progress-content-block > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; 
    margin-bottom: 4px;
}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 371px;
}

.steps img {
    width: 6px;
    height: 6px;
}

.step {
  display: flex;
  align-items: center;
}

.step span {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
  color: var(--font-dark-80, #3A466E);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E6E8ED;
}

.back-arrow-block{
    background: #F3F3F6;
    border: 1px solid #E6E8ED;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.chips-block{
    display: flex;
}

.personal-info-block-part{
    margin-bottom: 32px;
}

.chip{
    padding: 4px 12px 4px 12px;
    background-color: #43C9F3;
    display: flex;
    width: fit-content;
    align-items: center;
    font-size: 12px;
    height: 28px;
    font-weight: bold;
    color: #000;
    border-radius: 16px;
    margin-right: 12px;
    margin-bottom: 3px;
  }

.chip.active{
    background-color: #43C9F3;
    color: #fff;
}

.block-part-title{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 12px;
}

.block-part-title > span 
{
    font-weight: 500;
}

.step.active span {
  background-color: #43C9F3; /* Active step color */
}

.value-title{
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: #3A466E;
    margin-bottom: 8px;
}

.select-chips-block{
    margin-bottom: 32px;
}

.upload-document-button{
    border: 1px solid #000;
    padding: 8px 69px 8px 69px;
    background: #fff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
}

.custom-checkbox{
  margin-bottom: 12px;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox input[type="radio"] {
  display: none;
}

.custom-checkbox label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
}

.custom-checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #B5BAC9;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center center;
}
.custom-checkbox input[type="radio"]:checked + label:before,
.custom-checkbox input[type="checkbox"]:checked + label:before {
  background-color: #43C9F3;
  border-color: #43C9F3;
  background-image: url('../assets/images/check-sign.svg');
}


/* Optional: Styles for hover and focus states */
.custom-checkbox input[type="radio"]:hover + label:before,
.custom-checkbox input[type="radio"]:focus + label:before
.custom-checkbox input[type="checkbox"]:hover + label:before,
.custom-checkbox input[type="checkbox"]:focus + label:before {
  border-color: #43C9F3;
}

.itoggle {
  max-height:0;
  max-width:0;
  opacity:0;
  position:absolute;
}
input.itoggle + label {
  display:block;
  position:relative;
  box-shadow:inset 0 0 0 1px #d5d5d5;
  height:28px;
  width:52px;
  border-radius:15px;
  margin-bottom:0;
  background: #DADCE4;
}
input.itoggle + label:before {
  content: "";
  position: absolute;
  display: block;
  height: 29px;
  width: 52px;
  top: 0;
  left: 0;
  border-radius: 15px;
  background: rgba(19,191,17,0);
  transition: .25s ease-in-out;
}
input.itoggle + label:after {
  content: "";
  position: absolute;
  display: block;
  height: 24px;
  width: 24px;
  top: 2px;
  left: 3px;
  border-radius: 15px;
  background: white;
  transition: .25s ease-in-out;
}
input.itoggle:checked + label:before {
  width: 52px;
  background: #5EC975;
  content:"";
}
input.itoggle:checked + label:after {
  left: 26px;
}

@media (max-width: 612px) { 
  .personal-info-wrapper-block{
    width: calc(100% - 24px);
    padding: 24px;
  }
}
</style>