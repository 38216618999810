<template>
    <section class="container h-100 d-flex align-items-center">
        <div class="w-100 d-flex align-items-center flex-column main-wrapper">
            <h1 class="text-center fw-bold">Create your Therapist or Psychiatrist profile in three easy steps</h1>
            <p class="text-center description-text">The information you enter will be automatically saved as you complete your profile so you can return to finish it at any point.</p>
            <div class="steps-container steps-wrapper-block">
                <ul class="steps-list">
                    <li v-for="(step, index) in steps" :key="index" class="step-item">
                        <div class="step-number-container">
                            <div class="step-number"><span>{{ index + 1 }}</span></div>
                            <div v-if="index < steps.length - 1" class="step-line"></div>
                        </div>
                        <div class="step-content">
                            <div class="content-text">
                                <h3>{{ step.title }}</h3>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <button class="next-step-button" @click="redirectToPersonalProfileStepsPage">Create my Therapist profile</button>
        </div>
    </section>
</template>

<script>
export default {
  name: 'StepsComponent',
  data() {
    return {
      steps: [
        { title: 'Fill information about as a Therapist: Bio, issues you can treat, approaches etc.' },
        { title: 'Choose the services you would like to offer' },
        { title: 'Add your professional indemnity insurance and verify your identity' }
      ]
    }
  },
  methods: {
    redirectToPersonalProfileStepsPage(){
      this.$router.push('/therapist/personal-info');
    }
  },
}
</script>

<style scoped>

h1{
    font-size: 36px;
    margin-bottom: 12px;
}

.description-text {
    margin-bottom: 0px;
}

.main-wrapper{
    margin-top: 60px;
    margin-bottom: 60px;
}
.steps-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0px;
}

.step-item {
  display: flex;
  align-items: flex-start;
}

.step-number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
}

.step-number {
  padding: 12px;
  color: var(--font-dark-100, #13204E);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  border: 1px solid var(--main-colors-dark-15, #DADCE4);
  background: var(--MainColors-White, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(51, 83, 196, 0.08);
}

.step-number > span {
    width: 24px !important;
    height: 24px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}

.step-line {
  width: 2px;
  height: 32px; /* Adjust the height as needed */
  background-color: #43C9F3;
}

.step-content h3 {
  margin: 0;
  font-size: 1.1rem;
}

.step-content p {
  margin: 0;
}

.content-text > h3{
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
}

.steps-wrapper-block{
    width: 612px;
    padding: 48px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 32px;
    margin-top: 32px;
}

.next-step-button{
    width: 311px;
    margin-top: 18px;
}

@media (max-width: 612px) { 
  .steps-wrapper-block{
    width: calc(100% - 24px);
    padding: 24px;
  }

  h1{
    font-size: 24px;
  }
}
</style>