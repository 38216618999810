<template>
    <section class="container">
      <h1 class="text-center fs-1 fw-bold">Get help right now</h1>
      <div class="help-description-block col-xl-10 col-lg-10 offset-xl-1 offset-lg-1 col-md-12">
        <div>
          <p>Are you looking for fast, easy access to a qualified mental health practitioner? Where you don’t have to wait to be seen. Our “Get help right now” service connects you to a carefully selected group of fully vetted professionals who can help you in just a few minutes. When you select this service, we instantly alert our practitioners and match you with the first available expert. You'll get to speak with a practitioner from one of the following professions:</p>
          <ul class="list-unstyled ps-24 mt-24">
            
            <li class="mb-12 position-relative"
            v-tooltip="{
                        displayArrow: false,
                        global: true,
                        theme: {
                           
                            placement: 'right',
                            border: '3px solid #13204E',
                            padding: '8px 16px',
                            width: '234px',
                            'background-color': '#13204E',
                        },
                    }"
            >RcPsych registered Psychiatrist
              <i v-tooltip="'An RCPsych registered psychiatrist is a medical doctor who has completed specialist training in psychiatry and is registered with the professional membership body for psychiatrists in the UK.'">
                <img src="../../assets/images/info-icon.svg" alt="">
              </i>
            </li>
            <li class="mb-12 position-relative"
            >HCPC registered Psychologist
              <i v-tooltip="'An RCPsych registered psychiatrist is a medical doctor who has completed specialist training in psychiatry and is registered with the professional membership body for psychiatrists in the UK.'">
                <img src="../../assets/images/info-icon.svg" alt="">
              </i>
            </li>
            <li class="mb-12 position-relative"
            >BABCP accredited CBT Therapist
              <i v-tooltip="'An RCPsych registered psychiatrist is a medical doctor who has completed specialist training in psychiatry and is registered with the professional membership body for psychiatrists in the UK.'">
                <img src="../../assets/images/info-icon.svg" alt="">
              </i>
            </li>
            <li class="mb-12 position-relative"
            >BACP or UKCP registered Counsellor
              <i v-tooltip="'An RCPsych registered psychiatrist is a medical doctor who has completed specialist training in psychiatry and is registered with the professional membership body for psychiatrists in the UK.'">
                <img src="../../assets/images/info-icon.svg" alt="">
              </i>
            </li>
          </ul>
          <p>All of the above practitioners have been fully trained in psychological therapy skills and are in a position to give expert advice on what might be best for your condition. If they believe that they are a good match, they can continue to treat you. Otherwise, they may refer you to another practitioner from our registered list of providers who they feel would be better suited to assist you.
            <br>
            <br>
            Don't wait to get the mental health support you need. Choose our “Get help right now” service today and take the first step towards feeling better.</p>
        </div>
        <div class="mt-24 pt-16 text-center button-wrapper">
          <button class="btn btn-primary w-50" @click="$emit('changeComponent', 'HelpTypesComponent')">Next step</button>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  <style scoped>

  .button-wrapper{ 
    width: 100%;
    padding-top: 16px;
    font-size: 17px;
    font-weight: 600;
  }

  h1 {
    font-size: 38px;
    margin-bottom: 32px;
  }

  p {
    margin-bottom: 24px;
  }
  
  .container {
    padding: 60px 0px 60px;
  }

  ul {
    margin-bottom: 24px;
  }
  
  ul li {
    padding-left: 24px;
    font-weight: 500;
    margin-bottom: 12px
  }

  ul li:last-child {
    margin-bottom: 0px
  }
  
  ul li i {
    right: 0;
    cursor: pointer;
  }
  
  .btn-primary {
    padding: 8px 0;
    border-radius: 22px;
    background: linear-gradient(46deg, #43C9F3 0%, #3CAEE0 100%);
    color: #fff;
    border: none;
  }

  .help-description-block{
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 4px 8px 0px rgba(51, 83, 196, 0.08);
    padding: 48px
  }
  </style>
  