<template>
    <div v-if="isOpen" class="modal-overlay d-flex w-100 justify-content-end">
      <div class="modal-structure d-flex flex-column align-items-center" style="position: relative;">
        <div class="modal-header d-flex justify-content-end w-100">
          <img class="close-icon" src="../../assets/images/close-icon.svg" @click="closeModal" alt="">
        </div>
        <div class="w-100" style="position: relative;">
            <div class="small-wrapper-standart-block w-100" style="margin-bottom: 24px;">
                <p class="fw-bold" style="font-size: 18px; margin-bottom: 12px">1/3. Choose days and session types:</p>
                <div>
                    <div class="d-flex w-100 justify-content-between" style="padding-bottom: 24px;">
                        <div v-for="(weekName, index) in weekNames" :key="index">
                            <div class="week-name-item fw-bold" :class="{ active_week_name: weekName == selectedWeekName }" @click="selectWeekName(weekName)">{{ weekName }}</div>
                        </div>
                    </div>
                </div>
                <div class="toggle-switch-internal mb-3" v-if="therapistData && therapistData.addresses.length > 0">
                    <div class="toggle-option" :class="{ active: selectedOption === 'online' }" @click="selectedOption = 'online'">
                        <span>Online</span>
                    </div>
                    <div class="toggle-option" :class="{ active: selectedOption === 'face_to_face' }" @click="selectedOption = 'face_to_face'">
                        <span>Face-to-face</span>
                    </div>
                    <div class="toggle-slider" :class="{ 'face_to_face': selectedOption === 'face_to_face' }"></div>
                </div>
            </div>

            <div class="small-wrapper-standart-block w-100" v-if="selectedOption == 'online'" style="margin-bottom: 12px;">
                <p class="fw-bold" style="font-size: 18px; margin-bottom: 12px">2/3. Set up a work schedule:</p>

                <div class="d-flex w-100" style="margin-bottom: 8px;">
                    <div class="w-50" style="padding-right: 8px;">
                        <span class="fw-bold" style="font-size: 20px">From:</span>
                        <select name="" id="" v-model="fromValue" class="w-100 time-select">
                            <option :value="time.index" v-for="(time, index) in minValues" :key="index">
                                {{ time.value }}
                            </option>
                        </select>
                    </div>
                    <div class="w-50" style="padding-left: 8px;">
                        <span class="fw-bold" style="font-size: 20px">To:</span>
                        <select name="" id="" v-model="toValue" class="w-100 time-select" style="">
                            <option :value="time.index" v-for="(time, index) in maxValues" :key="index">
                                {{ time.value }}
                            </option>
                        </select>
                    </div>
                </div>
                <p v-if="therapistData != null">Timezone: {{ therapistData.timezones.timezone_name }}</p>
            </div>
            <div class="small-wrapper-standart-block w-100" v-else style="margin-bottom: 12px;">
                <div class="w-100 d-flex justufy-content-between">
                    <p class="fw-bold w-50" style="font-size: 18px; margin-bottom: 12px">2/3. Set up a work schedule:</p>
                    
                    <div class="w-50 d-flex justify-content-end">   
                        <span class="fw-bold" @click="duplicateFromOnline()" style="color: #2F80ED; font-size: 16px; cursor: pointer">Dublicate from online</span>
                    </div>
                </div>

                <div class="d-flex w-100" style="margin-bottom: 8px;">
                    <div class="w-50" style="padding-right: 8px;">
                        <span class="fw-bold" style="font-size: 20px">From:</span>
                        <select name="" id="" v-model="fromValue" class="w-100 time-select">
                            <option :value="time.index" v-for="(time, index) in minValues" :key="index">
                                {{ time.value }}
                            </option>
                        </select>
                    </div>
                    <div class="w-50" style="padding-left: 8px;">
                        <span class="fw-bold" style="font-size: 20px">To:</span>
                        <select name="" id="" v-model="toValue" class="w-100 time-select" style="">
                            <option :value="time.index" v-for="(time, index) in maxValues" :key="index">
                                {{ time.value }}
                            </option>
                        </select>
                    </div>
                </div>
                <p v-if="therapistData">Timezone: {{ therapistData.timezones.timezone_name }}</p>
            </div>
            <div class="small-wrapper-standart-block w-100" v-if="selectedOption == 'online'">
                <p class="fw-bold" style="font-size: 18px; margin-bottom: 16px">3/3. Choose work timeslots:</p>

                <div style="margin-bottom: 16px">   
                    <span class="fw-bold" @click="selectAllTimeSlotsForCurrentDayAndOption()" style="color: #2F80ED; font-size: 16px; cursor: pointer">Select all timeslots</span>
                </div>

                <div style="overflow-y: scroll; height: 400px">
                    <div class="timeslot" v-for="timeslot in finalValues" :key="timeslot.index" style="margin-bottom: 16px;">
                        <div class="d-flex justify-content-between" style="margin-bottom: 8px;">   
                            <span style="font-size: 20px; font-weight: 700; line-height: 28px; color: #313333">{{ timeslot.valueFullName }}</span>
                            <div class="gender-checkbox">
                                <input 
                                    type="checkbox" 
                                    class="gender-input" 
                                    :id="`provider-checkbox-${timeslot.index}`" 
                                    :checked="isChecked(timeslot.value)" 
                                    @change="updateTimeslot(timeslot, $event.target.checked)"
                                >
                                <label :for="`provider-checkbox-${timeslot.index}`" style="color: #fff">.</label>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="small-wrapper-standart-block w-100" v-else>
                <p class="fw-bold" style="font-size: 18px; margin-bottom: 12px">3/3. Choose work timeslots:</p>

                <div style="margin-bottom: 16px">   
                    <span class="fw-bold" @click="selectAllTimeSlotsForCurrentDayAndOption()" style="color: #2F80ED; font-size: 16px; cursor: pointer">Select all timeslots</span>
                </div>

                <div style="overflow-y: scroll; height: 400px">
                    <div class="timeslot" v-for="timeslot in finalValues" :key="timeslot.index" style="margin-bottom: 16px;">
                        <div class="d-flex justify-content-between" style="margin-bottom: 8px;">   
                            <span style="font-size: 20px; font-weight: 700; line-height: 28px; color: #313333">{{ timeslot.valueFullName }}</span>
                            <div class="gender-checkbox">
                                <input 
                                    type="checkbox" 
                                    class="gender-input" 
                                    :id="`provider-checkbox-${timeslot.index}`" 
                                    :checked="isChecked(timeslot.value)" 
                                    @change="updateTimeslot(timeslot, $event.target.checked)"
                                >
                                <label :for="`provider-checkbox-${timeslot.index}`" style="color: #fff">.</label>
                            </div>
                        </div>
                        <select 
                            v-if="therapistData && therapistData.addresses.length > 0" 
                            v-model="currentSelectedSchedule[selectedOption]"
                            @change="updateLocation(timeslot, $event.target.value)"
                        >
                            <option :value="location.location_id" v-for="location in therapsitData.addresses" :key="location.location_id">{{ location.location_name }}</option>
                        </select>
                        <hr>
                    </div>
                </div>
            </div> 
        </div> 
        <div class="d-flex justify-content-center w-100 fixed-button">
            <button class="next-step-button" @click="saveSchedule()" style="width: 311px;">Save schedule</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from '@/axios';
  
  export default {
    props: ['isOpen'],
    data(){
        return {
            selectedOption: "online",
            selectedWeekName: "Mon",
            fromValue: 10,
            toValue: 19,
            therapistData: null,
            times: [
                {
                    index: 8,
                    value: "08:00 AM",
                    valueFullName: "08:00 — 09:00"
                },
                {
                    index: 9,
                    value: "09:00 AM",
                    valueFullName: "09:00 — 10:00"
                },
                {
                    index: 10,
                    value: "10:00 AM",
                    valueFullName: "10:00 — 11:00"
                },
                {
                    index: 11,
                    value: "11:00 AM",
                    valueFullName: "11:00 — 12:00"
                },
                {
                    index: 12,
                    value: "12:00 PM",
                    valueFullName: "12:00 — 13:00"
                },
                {
                    index: 13,
                    value: "13:00 PM",
                    valueFullName: "13:00 — 14:00"
                },
                {
                    index: 14,
                    value: "14:00 PM",
                    valueFullName: "14:00 — 15:00"
                },
                {
                    index: 15,
                    value: "15:00 PM",
                    valueFullName: "15:00 — 16:00"
                },
                {
                    index: 16,
                    value: "16:00 PM",
                    valueFullName: "16:00 — 17:00"
                },
                {
                    index: 17,
                    value: "17:00 PM",
                    valueFullName: "17:00 — 18:00"
                },
                {
                    index: 18,
                    value: "18:00 PM",
                    valueFullName: "18:00 — 19:00"
                },
                {
                    index: 19,
                    value: "19:00 PM",
                    valueFullName: "19:00 — 20:00"
                },
                {
                    index: 20,
                    value: "20:00 PM",
                    valueFullName: "20:00 — 21:00"
                },
                {
                    index: 21,
                    value: "21:00 PM",
                    valueFullName: "21:00 — 22:00"
                },
                {
                    index: 22,
                    value: "22:00 PM",
                    valueFullName: null
                }
            ],
            weekNames: ["Mon", "Tue", "Wed", "Thr", "Fri", "Sut", "Sun"],
            schedules: [
                {
                    week_name: "Mon",
                    online: [],
                    face_to_face: [],
                },
                {
                    week_name: "Tue",
                    online: [],
                    face_to_face: [],
                },
                {
                    week_name: "Wed",
                    online: [],
                    face_to_face: [],
                },
                {
                    week_name: "Thr",
                    online: [],
                    face_to_face: [],
                },
                {
                    week_name: "Fri",
                    online: [],
                    face_to_face: [],
                },
                {
                    week_name: "Sut",
                    online: [],
                    face_to_face: [],
                },
                {
                    week_name: "Sun",
                    online: [],
                    face_to_face: [],
                },
            ],
        }
    },
    computed: {
        // Фильтрованные значения для минимального select
        minValues() {
            // Если maxValue не выбран, возвращаем все значения
            if (this.toValue === null) {
                return this.times;
            }
            // Возвращаем значения меньше или равные выбранному maxValue
            return this.times.filter(value => value.index <= this.toValue);
        },
        // Фильтрованные значения для максимального select
        maxValues() {
            // Если minValue не выбран, возвращаем все значения
            if (this.fromValue === null) {
                return this.times;
            }
            // Возвращаем значения больше или равные выбранному minValue
            return this.times.filter(value => value.index >= this.fromValue);
        },
        finalValues(){
            if(this.fromValue === null || this.toValue === null) {
                return this.times;
            }

            const timeslots = this.times.filter(value => value.index >= this.fromValue && value.index <= this.toValue);

            timeslots.splice(timeslots.length-1, 1);

            return timeslots
        },
        currentSelectedSchedule(){
            return this.schedules.find(schedule => schedule.week_name == this.selectedWeekName);
        },
    },
    methods: {
        async saveSchedule(){
            this.schedules.forEach((item) => {
                item.online.forEach((onlineItem) => {
                    onlineItem.timeslot = onlineItem.timeslot.substring(0, onlineItem.timeslot.length - 3);
                })
                item.face_to_face.forEach((faceToFaceItem) => {
                    faceToFaceItem.timeslot = faceToFaceItem.timeslot.substring(0, faceToFaceItem.timeslot.length - 3);
                })
            })
            
            await axios.post(`/therapist/setup-schedule`, {
                ...this.schedules
            },
            {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then(async (response) => {
                if(response.data == "success"){
                    this.$router.push('/therapist/schedule')
                }
            })
            
        },
        closeModal() {
            this.$emit('close');
        },
        selectWeekName(weekName){
            this.selectedWeekName = weekName;
        },
        async getTherapistData(){
            await axios.get(`/therapist/${this.$store.state.therapist.id}/get-profile`).then(async (response) => {
                this.therapistData = await response.data;
            })
        },
        duplicateFromOnline(){
            const currentScheduleValue = this.schedules.find((schedule) => schedule.week_name == this.selectedWeekName);

            currentScheduleValue.face_to_face = currentScheduleValue.online;
        },
        selectAllTimeSlotsForCurrentDayAndOption(){
            const currentScheduleValue = this.schedules.find((schedule) => schedule.week_name == this.selectedWeekName);

            this.finalValues.forEach((timeslotValue) => {
                currentScheduleValue[this.selectedOption].push({timeslot: timeslotValue.value, location_id: null});
            });
        },
        isChecked(value) {
            const currentScheduleValue = this.schedules.find((schedule) => schedule.week_name == this.selectedWeekName);
            // Проверяем, содержит ли текущий выбор timeslot заданный value
            return currentScheduleValue[this.selectedOption].some(item => item.timeslot === value);
        },
        updateTimeslot(timeslot, checked) {
            const currentScheduleValue = this.schedules.find((schedule) => schedule.week_name == this.selectedWeekName);

            if (checked) {
                // Добавляем timeslot к выбранному массиву, если checkbox отмечен
                currentScheduleValue[this.selectedOption].push({ timeslot: timeslot.value, location_id: null });
            } else {
                // Удаляем timeslot из выбранного массива, если checkbox снят
                const index = currentScheduleValue[this.selectedOption].findIndex(item => item.timeslot === timeslot.value);
                if (index !== -1) currentScheduleValue[this.selectedOption].splice(index, 1);
            }
        },
        updateLocation(timeslot, locationId) {
            const currentScheduleValue = this.schedules.find((schedule) => schedule.week_name == this.selectedWeekName);
            // Обновляем location_id для выбранного timeslot
            
            const item = currentScheduleValue[this.selectedOption].find(item => item.timeslot === timeslot.value);
            if (item) item.location_id = locationId;
        }
    },
    async mounted(){
        await this.getTherapistData();
    },
    watch: {
        // Следим за изменениями minValue и корректируем maxValue при необходимости
        minValue(newVal) {
            if (newVal !== null && newVal > this.toValue) {
                this.toValue = newVal;
            }
        },
        // Следим за изменениями maxValue и корректируем minValue при необходимости
        maxValue(newVal) {
            if (newVal !== null && newVal < this.fromValue) {
                this.fromValue = newVal;
            }
        },
    },
  }
  </script>

  <style scoped>
    .active_week_name{
        background: #000;
        color: #fff;
        border-radius: 4px;
    }

    .small-wrapper-standart-block{
        background: #fff;
        padding: 24px;
        border-radius: 16px;
    }

    .week-name-item{
        width: 48px;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;
    }

    .time-select{
        background: #fff; 
        padding: 10px 28px 10px 28px; 
        border-radius: 8px; 
        border: 1px solid #E8E9EA;
        font-size: 18px;
        margin-top: 4px;
    }

    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
    }

    .modal-structure {
      background-color: #FAFCFF;
      padding: 20px;
      height: 100%;
      padding: 24px 48px 24px 48px;
      width: 535px;
    }

    .modal-header {
      margin-bottom: 22px;
    }

    .close-icon{
      cursor: pointer;
    }

    .gender-checkbox {
        position: relative;
        margin: 0px;
    }

    .gender-checkbox input[type="checkbox"] {
        display: none;
    }

    .gender-checkbox label {
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        line-height: 20px;
    }
    
    .gender-checkbox label:before {
        content: '';
        position: absolute;
        left: 0;
        top: -1;
        width: 18px;
        height: 18px;
        border: 1px solid #B5BAC9;
        border-radius: 8px;
    }
    
    .gender-checkbox input[type="checkbox"]:checked + label:before {
        background-color: #fff;
        border-color: #13204E;
    }
    
    .gender-checkbox input[type="checkbox"]:checked + label:after {
        content: '';
        position: absolute;
        width:10px;
        height: 10px;
        left: 4px;
        top: 4px;
        color: white;
        font-size: 16px;
        background: #13204E;
        border-radius: 16px;
    }

    .fixed-button {
        position: absolute; /* Абсолютное позиционирование для кнопки */
        bottom: 0; /* Расположение кнопки в нижней части контейнера */
        left: 0; /* Расположение кнопки в левой части контейнера */
        width: 100%; /* Ширина кнопки */
        padding: 10px 0 20px 0; /* Вертикальные отступы для кнопки */
        background-color: #fff; /* Цвет фона кнопки */
        color: white; /* Цвет текста кнопки */
        border: none; /* Удаление границы кнопки */
        cursor: pointer; /* Стиль курсора при наведении на кнопку */
    }
  </style>