// connectycube-config.js
import ConnectyCube from 'connectycube';

const CONFIG = {
    appId: 7687,
    authKey: 'MSbgZrysDQj5YY9',
    authSecret: 'xBaUSXHh9-h6C9b'
};

// Инициализация SDK
ConnectyCube.init(CONFIG);

export default ConnectyCube;
