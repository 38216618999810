<template>
    <section class="container h-100 d-flex align-items-center">
        <div class="w-100 d-flex align-items-center flex-column main-wrapper">
            <h1 class="text-center fw-bold">Terms and Conditions for Private Therapy App</h1>
            <div class="steps-container steps-wrapper-block w-100">
                <h5 class="fw-bold">1. Introduction</h5>
                
                <p>Welcome to Private Therapy App. These Terms and Conditions govern your use of our website located at [<a href="http://therapyapp.co">therapyapp.co</a>] and our mobile application (collectively, "the Service"). By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, you do not have permission to access the Service.</p>

                <h5 class="fw-bold">2. Use of the Service</h5>

                <p>The Service is intended for users who are at least 18 years old. By using the Service, you represent and warrant that you are of legal age to form a binding contract with Private Therapy App.</p>

                <h5 class="fw-bold">3. User Accounts</h5>

                <p>When you create an account with us, you guarantee that the information you provide is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.</p>

                <h5 class="fw-bold">4. Intellectual Property</h5>

                <p>The Service and its original content, features, and functionality are and will remain the exclusive property of Private Therapy App and its licensors.</p>
                
                <h5 class="fw-bold">5. Prohibited Uses</h5>

                <p>You may use the Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service:</p>

                <ul>
                    <li>In any way that violates any applicable national or international law or regulation.</li>
                    <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way.</li>
                </ul>

                <h5 class="fw-bold">6. Termination</h5>

                <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever, including without limitation if you breach the Terms.</p>

                <h5 class="fw-bold">7. Limitation of Liability</h5>

                <p>In no event shall Private Therapy App, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>

                <h5 class="fw-bold">8. Governing Law</h5>

                <p>These Terms shall be governed and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law provisions.</p>

                <h5 class="fw-bold">9. Changes to Terms</h5>

                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will provide at least 30 days' notice prior to any new terms taking effect. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms.</p>

                <h5 class="fw-bold">10. Contact Us</h5>

                <p>If you have any questions about these Terms, please contact us at</p>

                <p>[<a href="mailto:team@privatetherapyclinic.com">team@privatetherapyclinic.com</a>].</p>

                <p class="fst-italic">Last Updated: January 10, 2024</p>   
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'StepsComponent',
  data() {
    return {
    }
  }
}
</script>

<style scoped>

h1{
    font-size: 36px;
    margin-bottom: 12px;
}

h5 {
  margin-bottom: 4px;
}

p{
  margin-bottom: 24px;
}

.description-text {
    margin-bottom: 0px;
}

.main-wrapper{
    margin-top: 60px;
    margin-bottom: 60px;
}
.steps-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0px;
}

.step-item {
  display: flex;
  align-items: flex-start;
}

.step-number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
}

.step-number {
  padding: 12px;
  color: var(--font-dark-100, #13204E);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  border: 1px solid var(--main-colors-dark-15, #DADCE4);
  background: var(--MainColors-White, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(51, 83, 196, 0.08);
}

.step-number > span {
    width: 24px !important;
    height: 24px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}

.step-line {
  width: 2px;
  height: 32px; /* Adjust the height as needed */
  background-color: #43C9F3;
}

.step-content h3 {
  margin: 0;
  font-size: 1.1rem;
}

.step-content p {
  margin: 0;
}

.content-text > h3{
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
}

.steps-wrapper-block{
    width: 612px;
    padding: 48px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 32px;
    margin-top: 32px;
}

.next-step-button{
    width: 311px;
    margin-top: 18px;
}

@media (max-width: 612px) { 
  .steps-wrapper-block{
    width: calc(100% - 24px);
    padding: 24px;
  }

  h1{
    font-size: 24px;
  }
}
</style>