<template>
  <div v-if="isOpen" class="modal-overlay d-flex w-100 justify-content-end">
    <div class="modal-structure d-flex flex-column align-items-center col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-6">
      <div class="modal-header d-flex justify-content-end w-100">
        <img class="close-icon" src="../../assets/images/close-icon.svg" @click="closeModal" alt="">
      </div>
      
      <div class="modal-body w-100 d-flex flex-column align-items-center">
          <h3 class="text-center sidebar_title">Read guidelines</h3>
          
          <div style="margin-top: 24px;">
            <h4>Example:</h4>
            <p>I'm a Portuguese Clinical Psychologist (Master Clinical Psychology and Counselling), with European Accreditation in Clinical and Health + Educational Psychology (Europsy PT-051760-201907) and Chartered on Psychological Society of Ireland (PSI Member – M10601)</p>
          </div>
          
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  props: ['isOpen'],
  data(){
    return {
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    
  }
}
</script>

<style scoped>

h4{
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
}

p{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-structure {
  background-color: #FAFCFF;
  padding: 20px;
 
  height: 100%;
  padding: 24px 96px 24px 96px;
}

.modal-header {
  margin-bottom: 22px;
}

.close-icon{
  cursor: pointer;
}

  @media (max-width: 768px) {
    .modal-structure{
      padding: 24px;
    }
  }
  
</style>