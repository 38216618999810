<template>
    <section class="container add-card-section d-flex flex-column h-100 align-items-center justify-content-center">
      <div class="w-100 d-flex align-items-center flex-column">
        <h2 class="fw-bold" style="margin-bottom: 38px">Adding new card</h2>
  
        <div class="card-form col-xl-6 col-lg-6 col-md-8 col-xs-12">
          <p class="form-title">Bank Card</p>
          <div class="w-100">
              <label class="form-label">Card number:</label>
              <input 
                  type="text" 
                  v-model="formattedCardNumber" 
                  class="w-100 card-number-input" 
                  @input="formatCardNumber()" 
                  maxlength="19" 
                  placeholder="XXXX XXXX XXXX XXXX" 
                  @keypress="onlyNumber($event)"
              />
          </div>
          
          <div class="w-100 d-flex">
              <div class="input-group-bottom w-50">
                  <label class="form-label">Expiry date:</label>
                  <input v-model="expiryDate" class="w-100 card-form-input" @input="formatExpiryDate" maxlength="7" placeholder="MM / YY" />
              </div>
          
              <div class="input-group-bottom w-50">
                  <label class="form-label">Security code:</label>
                  <input v-model="securityCode" class="w-100 card-form-input" type="password" placeholder="CVV" maxlength="3"/>
              </div>
          </div>
          
        </div>
        
        
        <div class="col-xl-4 col-lg-4 col-md-6 d-flex align-items-center flex-column">
          <div class="custom-checkbox">
              <input :id="`terms_and_cond`" type="checkbox" v-model="rememberCard" :value="true"/>
              <label :for="`terms_and_cond`" class="remember-card-label"><p>Remember the card for the next sessions</p></label>
          </div>
      
          <p class="agree-terms">By adding a debit/credit card, you agree to the</p>
          <a href="/terms-and-conditions" class="link agree-terms">Terms & Conditions</a>

          <button @click="addCard" style="border-radius: 50px; margin-top: 20px;" :class="{notActive: !rememberCard}" class="next-step-button">Start subscription</button>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import axios from '@/axios';
  
  export default {
    data() {
      return {
        rawCardNumber: "",
        formattedCardNumber: "",
        expiryDate: "",
        month: "",
        year: "",
        securityCode: "",
        rememberCard: false,
        // issueId: null,
      };
    },
    methods: {
      formatCardNumber() {
        this.rawCardNumber = this.formattedCardNumber.replace(/\s+/g, '');
        let value = this.rawCardNumber.replace(/(.{4})/g, '$1 ').trim();
        if(value.length > 19) {
            value = value.substring(0, 19);
        }
        this.formattedCardNumber = value;
      },
      formatExpiryDate() {
        const rawDate = this.expiryDate.replace(/\D/g, '');
        if (rawDate.length >= 3) {
          this.month = rawDate.substring(0, 2);
          this.year = rawDate.substring(2);
          this.expiryDate = `${this.month} / ${this.year}`;
        } else {
          this.month = rawDate;
          this.year = "";
          this.expiryDate = this.month;
        }
      },
      onlyNumber(event) {
        if(!Number(event.key)) {
            event.preventDefault();
        }
      },
      processPayment() {
            const rawDate = this.expiryDate.replace(/\D/g, '');
            if (rawDate.length >= 3) {
                this.month = rawDate.substring(0, 2);
                this.year = rawDate.substring(2);
                this.expiryDate = `${this.month} / ${this.year}`;         
            } else {
                this.month = rawDate;
                this.year = "";
                this.expiryDate = this.month;
            }
        },
      async addCard(){
        if(this.rememberCard){
          try {
              await axios.post('therapist/payment/add-new-card', {
                'card_number': this.rawCardNumber,
                'expiry_month': this.month,
                'expiry_year': this.year,
                'cvv_number': this.securityCode,
              }, 
              {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                    Accept: 'application/json'
                }
              }).then(async () => {
                await axios.get('therapist/payment/card',
                {
                  headers: {
                      Authorization: `Bearer ${this.$store.state.accessToken}`,
                      Accept: 'application/json'
                  }
                }).then(async (response) => { 
                  await axios.post('therapist/subscription/subscribe', {
                    'payment_method_id': response.data.payment_method_id
                  }, 
                  {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                        Accept: 'application/json'
                    }
                  }).then(async (response) => {
                    if(response.data == 'You have successfully subscribed'){
                      this.$router.push('/therapist/dashboard');
                    }
                  });
                })  
              })
          } catch (error) {
              // this.$router.push('/');
              console.error('Ошибка при получении данных от API:', error)
          }
        }
      },
    }
  };
  </script>
  
  <style scoped>
  .card-form {
    display: flex;
    padding: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 16px;
    border: 1px solid var(--main-colors-dark-10, #E6E8ED);
    background: var(--main-colors-white, #FFF);

    /* Shadow/Small Dark */
    box-shadow: 0px 4px 8px 0px rgba(51, 83, 196, 0.08);
  }
  
  button {
    background-color: #007BFF;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }

  .add-card-section{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .input-group-bottom:first-child{
    padding-right: 12px;
  }

  .input-group-bottom:last-child{
    padding-left: 12px;
  }

  .form-title{
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }

  .form-label{
    color: var(--font-dark-100, #13204E);
    /* Type/16px/Semibold */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 12px;
  }

  .card-number-input{
    padding: 10px 12px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    margin-bottom: 18px;
    background: url('../../assets/images/cards.png') no-repeat right 10px center, var(--main-colors-dark-5, #F3F3F6);
    background-size: contain;
    border: none;
  }

  .card-form-input{
    padding: 10px 12px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--main-colors-dark-5, #F3F3F6);
    border: none;
  }


.custom-checkbox{
    margin-bottom: 0px;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
}

.custom-checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #B5BAC9;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center center;
}

.custom-checkbox input[type="checkbox"]:checked + label:before {
  background-color: #43C9F3;
  border-color: #43C9F3;
  background-image: url('../../assets/images/check-sign.svg');
}

/* Optional: Styles for hover and focus states */
.custom-checkbox input[type="checkbox"]:hover + label:before,
.custom-checkbox input[type="checkbox"]:focus + label:before {
  border-color: #43C9F3;
}

.link{
    color: rgba(47, 128, 237, 0.80);
}

.notActive{
  background: var(--main-colors-blue-40, #B4E9FA);
}

.notActive:hover{
  background: var(--main-colors-blue-40, #B4E9FA);
}

.remember-card-label{ 
  margin-top: 32px;
  margin-bottom: 20px;
}

.remember-card-label > p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0px;
}

.agree-terms{
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0px;
}

  </style>