import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import HomePage from '../views/HomePage.vue'
import TherapistSearch from '../views/TherapistSearch.vue'
import GetHelp from '../views/GetHelp.vue'
import SignUp from '../views/SignUp.vue'
import SignIn from '../views/SignIn.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import SendReferal from '../views/SendReferal.vue'
import RecommendedTherapy from '../views/RecommendedTherapy.vue'
import SearchResult from '../views/SearchResult.vue'
import ListOfTherapistsWithSearch from '../views/ListOfTherapistsWithSearch.vue'
import MySessions from '../views/MySessions.vue'
import AddCard from '../views/Components/AddCardComponent.vue'
import SearchTherapistsInGetHelpProcess from '../views/SearchTherapistsInGetHelpProcess.vue'
import GetHelpSessionIsBooked from '../views/GetHelpSessionIsBooked.vue'
import SessionIsBooked from '../views/SessionIsBooked.vue'
import BookTherapist from '../views/BookTherapist.vue'
import TherapistSignIn from '../views/TherapistSignIn.vue'
import TherapistSignUp from '../views/TherapistSignUp.vue'
import CreateTherapistProfileEasySteps from '../views/CreateTherapistProfileEasySteps.vue'
import PersonalInformation from '../views/PersonalInformation.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import ProfessionalInformation from "../views/ProfessionalInformation.vue"
import ServiceInfo from "../views/ServiceInfo.vue"
import ProfilePreview from "../views/ProfilePreview.vue"
import CompleteTherapistProfile from "../views/CompleteTherapistProfile.vue"
import TherapistDashboard from "../views/TherapistDashboard.vue"
import TherapistCalendar from "../views/TherapistCalendar"
import TherapistSchedulePage from "../views/TherapistSchedulePage"
import ChatsPage from "../views/ChatsPage.vue"
import ChatPage from "../views/ChatPage.vue"
import FrequentlyAskedQuestions from "../views/FrequentlyAskedQuestions.vue"
import ProfileView from "../views/ProfileView.vue"

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/sign-up',
    name: 'sign_up',
    component: SignUp
  },
  {
    path: '/sign-in',
    name: 'sign_in',
    component: SignIn
  },
  {
    path: '/therapist/sign-in',
    name: 'therapist_sign_in',
    component: TherapistSignIn
  },
  {
    path: '/therapist/sign-up',
    name: 'therapist_sign_up',
    component: TherapistSignUp
  },
  {
    path: '/therapist',
    meta: { requiresAuth: true, role: 'therapist' }, // Мета-информация, применимая ко всей группе
    children: [
      {
        path: 'add-card',
        name: 'add_card',
        component: AddCard,
        props: true
      },
      {
        path: 'easy-steps',
        name: 'therapist_easy_steps',
        component: CreateTherapistProfileEasySteps
      },
      {
        path: 'personal-info',
        name: 'therapist_personal_info',
        component: PersonalInformation
      },
      {
        path: 'professional-info',
        name: 'therapist_professional_info',
        component: ProfessionalInformation
      },
      {
        path: 'service-info',
        name: 'therapist_service_info',
        component: ServiceInfo
      },
      {
        path: 'profile-preview',
        name: 'therapist_profile_preview',
        component: ProfilePreview
      },
      {
        path: 'profile-view',
        name: 'therapist_profile_view',
        component: ProfileView
      },
      {
        path: 'complete',
        name: 'therapist_complete',
        component: CompleteTherapistProfile
      },
      {
        path: 'dashboard',
        name: 'therapist_dashboard',
        component: TherapistDashboard
      },
      {
        path: 'calendar',
        name: 'therapist_calendar',
        component: TherapistCalendar
      },
      {
        path: 'schedule',
        name: 'therapist_schedule',
        component: TherapistSchedulePage
      },
    ],
  },
  {
    path: '/client',
    meta: { requiresAuth: true, role: 'client' }, // Мета-информация, применимая ко всей группе
    children: [
      {
        path: '/recommended-therapy',
        name: 'recommended_therapy',
        component: RecommendedTherapy,
        props: true
      },
      {
        path: '/recommended-therapist',
        name: 'recommended_therapist',
        component: SearchResult,
      },
      {
        path: '/found-therapists',
        name: 'found_therapists',
        component: ListOfTherapistsWithSearch,
        props: true
      },
      {
        path: '/book-therapist',
        name: 'book_therapist',
        component: BookTherapist,
        props: true
      },
      {
        path: '/my-sessions',
        name: 'my_sessions',
        component: MySessions,
        props: true
      },
      {
        path: '/find-therapist',
        name: 'therapist_search',
        component: TherapistSearch
      },
      {
        path: '/get-help',
        name: 'get_help',
        component: GetHelp
      },
      {
        path: '/get-help/search-therapist',
        name: 'get_help_search_therapists',
        component: SearchTherapistsInGetHelpProcess
      },
      {
        path: '/get-help/session-is-booked',
        name: 'get_help_session_is_booked',
        component: GetHelpSessionIsBooked
      },
      {
        path: '/session-is-booked',
        name: 'session_is_booked',
        component: SessionIsBooked
      },
    ]
  },
  {
    path: '/chats',
    name: 'chats_page',
    component: ChatsPage,
    props: true
  },
  {
    path: '/chat',
    name: 'chat_page',
    component: ChatPage,
    props: true
  },
  {
    path: '/forgot-password',
    name: 'forgot_password',
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    name: 'reset_password',
    component: ResetPassword
  },
  {
    path: '/send-referal',
    name: 'send_referal',
    component: SendReferal
  },
  {
    path: '/privacy-policy',
    name: 'privacy_policy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-and-conditions',
    name: 'terms_and_conditions',
    component: TermsAndConditions
  },
  {
    path: '/frequently-asked-questions',
    name: 'frequently_asked_questions',
    component: FrequentlyAskedQuestions
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Проверяем, требует ли маршрут аутентификации и соответствующей роли
  if (to.matched.some(record => record.meta.requiresAuth && record.meta.role === 'therapist')) {
    if (store.state.accessToken == null || store.state.therapist.id == null) { // isAuthenticated и isTherapist - функции проверки
      next({ path: '/sign-in' }); // Если не аутентифицирован или не терапевт, перенаправляем на страницу входа
    } else {
      next(); // В противном случае разрешаем переход
    }
  } else if(to.matched.some(record => record.meta.requiresAuth && record.meta.role === 'client')) {
    if (store.state.therapist.id != null) { // isAuthenticated и isTherapist - функции проверки
      next({ path: '/sign-in' }); // Если не аутентифицирован или не терапевт, перенаправляем на страницу входа
    } else {
      next(); // В противном случае разрешаем переход
    }
  } else {
    next(); // Для маршрутов без специфических требований просто разрешаем переход
  }
});

export default router
