import axios from 'axios';
import store from './store'; // Путь до вашего store
import router from './router'; // Путь до вашего router

const instance = axios.create({
  baseURL: 'https://api.therapyapp.co/api/v7',
  // baseURL: 'http://localhost:8000/api/v7',
});

instance.interceptors.response.use(
  response => response,
  async error => {
    console.log(store.state.refreshToken);
    if (error.response.status === 401 && (error.config.url !== '/client/refresh-token' || error.config.url !== '/therapist/refresh-token' ) && store.state.refreshToken != null) {
      // Если ответ 401 и запрос не на обновление токена
      try {
        let response = null;

        if(store.state.user.firstName != null){
          response = await axios.post('https://api.therapyapp.co/api/v7/client/refresh-token', {
            refresh_token: store.state.refreshToken,
          }); 
        }else if(store.state.therapist.id != null){
          response = await axios.post('https://api.therapyapp.co/api/v7/therapist/refresh-token', {
            refresh_token: store.state.refreshToken,
          }); 
        }

        store.dispatch('setTokens', {access: response.data.token, refresh: response.data.refresh_token}); // Обновляем токены в store
        error.config.headers['Authorization'] = `Bearer ${store.state.accessToken}`;
        console.log(store.state.accessToken);
        return axios(error.config); // Повторяем запрос с новым токеном
      } catch (error) {
        store.dispatch('setTokens', { access: null, refresh: null });
        if(store.state.therapist.id != null){
          router.push('/therapist/sign-in');
        }else{
          router.push('/sign-in');
        }
        
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;